import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../services/config.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ResourceService } from '../../../services/resource.service';

import { TranslatorService } from '../../../services/translator.service';
import { TranslatePipe } from '../../../utils/pipes';
import { HelpersService } from '../../../services/helpers.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../../modals/login/login.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    ResourceService = inject(ResourceService);
    appName: string;
    routes: any;
    user: any;
    user$: any;
    profileOpen: boolean = null;
    ready: boolean = false;
    darkMode: boolean;
    publicLanguage: string = 'nl';
    languageOptions: any[] = this.ResourceService.getLanguages();
    mobileMenuOpen: boolean = false;
    speciesPartCodes: any[] = [];
    hideNav: boolean = false;
    isAdmin: boolean = false;
    adminCompaniesToAccept: number = null;

    constructor(
        public ConfigService: ConfigService,
        public route: ActivatedRoute,
        public router: Router,
        public AuthenticationService: AuthenticationService,
        private TranslatorService: TranslatorService,
        private TranslatePipe: TranslatePipe,
        private ModalService: NgbModal,
        public HelpersService: HelpersService
    ) {
        this.HelpersService.getAdminCompaniesToAcceptObservable().subscribe((next) => {
            this.adminCompaniesToAccept = next;
        });
    }

    setProfileOpen(value: boolean) {
        setTimeout(() => {
            this.profileOpen = value;
        }, 1);
    }

    logoClick($event: Event) {
        if (this.hideNav) {
            $event.preventDefault();
            $event.stopPropagation();
        } else {
            this.router.navigate([this.HelpersService.getUrl('', 'home')]);
        }
    }

    ngOnInit(): void {
        this.appName = this.ConfigService.getConfig().appName;
        this.speciesPartCodes = this.ResourceService.getSpeciesPartsCodes();
        this.ready = true;
        this.AuthenticationService.getUser().subscribe((next: any) => {
            if (next) {
                this.user = { ...next };
                this.isAdmin = next?.company?.isAdmin;
            }
        });
        setTimeout(() => {
            if (this.isAdmin) this.HelpersService.getAdminCompaniesToAccept();
        }, 1);
        this.publicLanguage = this.TranslatorService.getPublicLanguage();
        const backto = this.HelpersService.getParam('backto');
        if (backto && window.location.href.indexOf('select-company') == -1) {
            this.openLoginModal(backto);
        }
        if (window.location.href.indexOf('select-company') != -1) {
            this.hideNav = true;
        }
    }

    openLoginModal(backto?) {
        const modalRef = this.ModalService.open(LoginComponent, {
            windowClass: 'main-modal login-modal'
        });
        modalRef.componentInstance.backto = backto || null;
    }

    setLanguage(language: string) {
        const newUrl = window.location.href.replace(`/${this.publicLanguage}`, `/${language || 'nl'}`);
        this.TranslatorService.setPublicLanguage(language || 'nl');
        this.publicLanguage = this.TranslatorService.getPublicLanguage();
        setTimeout(() => {
            window.location.href = newUrl;
        }, 5);
    }

    toggleProfileOpen() {
        if (this.profileOpen) {
            this.profileOpen = null;
        } else this.profileOpen = true;
    }

    selectSpecies(type: string) {
        localStorage.setItem('indexType', type);
        this.router.navigate([this.HelpersService.getUrl(type)]);
    }
}
