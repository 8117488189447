import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { ConfigService } from './services/config.service';
import { ResourceService } from './services/resource.service';
import { TranslatorService } from './services/translator.service';
import { Config } from './models/common';
import { AuthenticationService } from './services/authentication.service';
import { HelpersService } from './services/helpers.service';
import { ActivatedRoute, ActivationStart, Router, RoutesRecognized } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from './utils/pipes';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    ready$ = new BehaviorSubject<boolean>(false);
    fetchedInitialRouteData: boolean = false;
    isPublic$ = new BehaviorSubject<boolean>(false);
    config: Config;
    alert: string = '';

    constructor(
        public ConfigService: ConfigService,
        private TitleService: Title,
        private MetaService: Meta,
        private AuthenticationService: AuthenticationService,
        private ResourceService: ResourceService,
        private TranslatorService: TranslatorService,
        public HelpersService: HelpersService,
        public Router: Router,
        private toastr: ToastrService,
        public TranslatePipe: TranslatePipe
    ) {}

    ngOnInit(): void {
        this.MetaService.addTag({
            name: 'description',
            content:
                'De Meatindex is een initiatief van FEBEV en is sinds 2008 de referentie voor de communicatie van de prijsfluctuaties van deelstukken in de varkensketen.'
        });
        this.config = this.ConfigService.getConfig();
        this.TitleService.setTitle('Meatindex');

        // listen to router events
        this.Router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                if (route && route.data && route.data.showNewsletter) {
                    this.HelpersService.setShowNewsletter(true);
                } else {
                    this.HelpersService.setShowNewsletter(false);
                }
                if (route && route.data && route.data.showCaptcha) {
                    this.HelpersService.setShowCaptcha(true);
                } else {
                    this.HelpersService.setShowCaptcha(false);
                }
            }
            if (event instanceof ActivationStart) {
                if (
                    !event.snapshot.params ||
                    !event.snapshot.params.lang ||
                    ['nl', 'fr'].indexOf(event.snapshot.params.lang) == -1
                ) {
                    window.location.href = this.HelpersService.getUrl('', 'home');
                    // console.log('REDIRECT');
                } else {
                    this.TranslatorService.setPublicLanguage(event.snapshot.params.lang);
                }
            }
            if (this.fetchedInitialRouteData) {
                return;
            }
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                if (route && route.data && route.data.isPublic) {
                    this.isPublic$.next(true);
                } else {
                    this.isPublic$.next(false);
                }

                this.fetchedInitialRouteData = true;
                if (!route || !route.data.isPublic) {
                    // console.log('private route');
                    // private route
                    this.AuthenticationService.fetchUser().subscribe(
                        (next) => {
                            // console.log('user is logged in');
                            // logged in
                            if (route && route.data && route.data.logout) {
                                this.AuthenticationService.softLogout();
                                window.location.reload();
                                return;
                            }
                            this.initPrivateRoute(next.language, next);
                        },
                        (error: any) => {
                            // console.log('user is not logged in');
                            // not logged in on private route -> redirect
                            console.log(window.location.pathname);
                            window.location.href = this.HelpersService.getUrl('', 'home', window.location.pathname);
                        }
                    );
                } else {
                    // console.log('public route');
                    // public route
                    this.AuthenticationService.fetchUser().subscribe(
                        (next: any) => {
                            // console.log('user is logged in');
                            // logged in
                            if (route && route.data && route.data.logout) {
                                this.AuthenticationService.softLogout();
                                window.location.reload();
                                return;
                            }
                            this.fetchedInitialRouteData = true;
                            this.initPrivateRoute(next.language, next);
                        },
                        (error: any) => {
                            // console.log('user is not logged in');
                            // not logged in
                            const publicLanguage = this.TranslatorService.getPublicLanguage();
                            this.initPublicRoute(publicLanguage);
                        }
                    );
                }
            }
        });
    }

    public initPrivateRoute(language?, user?) {
        if (
            user &&
            user.company &&
            user.company.type == 'member' &&
            window.location.pathname.indexOf('register-activities') == -1 &&
            (!user.activities || !user.activities.length)
        ) {
            window.location.href = this.HelpersService.getUrl('register-activities');
        }
        if (language && this.TranslatorService.getPublicLanguage() != language) {
            const newUrl = window.location.href.replace(
                `/${this.TranslatorService.getPublicLanguage()}`,
                `/${language || 'nl'}`
            );
            this.TranslatorService.setPublicLanguage(language || 'nl');
            window.location.href = newUrl;
            return;
        }

        const observables$: Observable<any>[] = [of(null)];
        observables$.push(...this.ResourceService.init());
        observables$.push(this.TranslatorService.init(language || 'nl'));

        forkJoin(observables$).subscribe((next) => {
            this.afterInit();
            this.setReady(true);
        });
    }

    public initPublicRoute(language?) {
        const observables$: Observable<any>[] = [of(null)];
        observables$.push(...this.ResourceService.initPublic());
        observables$.push(this.TranslatorService.init(language || 'nl'));
        forkJoin(observables$).subscribe((next) => {
            this.afterInit();
            this.setReady(true);
        });
    }

    public afterInit() {
        const user: any = this.AuthenticationService.getUserValue();
        const msg = this.HelpersService.getParam('msg');
        switch (msg) {
            case 'invalidlink':
                setTimeout(() => {
                    this.toastr.error('', this.TranslatePipe.transform('toast_invalidlink_title'));
                }, 500);
                break;
            case 'passwordreset':
                setTimeout(() => {
                    this.toastr.success('', this.TranslatePipe.transform('toast_passwordreset_title'));
                }, 500);
                break;
            default:
                break;
        }
        if (user && user.company && user.company.status == 2 && user.activities && user.activities.length) {
            this.alert = this.TranslatePipe.transform('alert_unconfirmed_user');
        }
    }

    public getReady() {
        return this.ready$.asObservable();
    }

    private setReady(ready: boolean) {
        this.ready$.next(ready);
    }

    // public showHeader() {
    //     return !this.isPublic$.getValue();
    // }
}
