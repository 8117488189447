import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private DefaultService: DefaultService) {}

    getUsers() {
        return this.DefaultService.companyGetUsersForCompany();
    }

    inviteUser(FORM) {
        return this.DefaultService.companyInviteUserToCompany(FORM);
    }

    deleteUser(id) {
        return this.DefaultService.companyDeleteUser(id);
    }

    forgotPassword(email) {
        return this.DefaultService.userSendForgotPassword({ email: email });
    }

    getUserForHash(id, hash) {
        return this.DefaultService.userGetUserForHash(id, hash);
    }

    firstlogin(id, hash, FORM) {
        return this.DefaultService.userFirstlogin(FORM, id, hash);
    }

    resetPassword(id, hash, FORM, token) {
        return this.DefaultService.userResetPassword(FORM, id, hash, token);
    }
}
