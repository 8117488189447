import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../services/resource.service';
import { AuthenticationService } from '../../services/authentication.service';
import { HelpersService } from '../../services/helpers.service';
import { TranslatorService } from '../../services/translator.service';
import { ToastrService } from 'ngx-toastr';

declare const grecaptcha: any;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    TranslatePipe = inject(TranslatePipe);
    TranslatorService = inject(TranslatorService);
    formSTR: any = {
        user: { language: this.TranslatorService.getCurrentLanguage() },
        company: { federationIds: [], vat: '' }
    };
    validation: any = { user: {}, company: {} };
    saving: boolean = false;
    changes: boolean = true;
    successState: boolean = false;
    failureState: boolean = false;

    constructor(
        private ModalService: NgbModal,
        private Router: Router,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {}

    submit() {
        let FORM = JSON.parse(JSON.stringify(this.formSTR));
        if (FORM.company.vat) {
            FORM.company.vat = 'BE0' + FORM.company.vat.toUpperCase().replace(/\./g, '').replace(/\s/g, '');
        }
        if (FORM.company.phone == '') {
            delete FORM.company.phone;
        }
        if (FORM.user.mobile == '') {
            delete FORM.user.mobile;
        }
        let that = this;
        this.saving = true;
        grecaptcha.ready(function () {
            grecaptcha.execute('6LfXbsQaAAAAAIpbJZelj4SW37Md5HVBNHvP0kns', { action: 'submit' }).then(function (token) {
                that.AuthenticationService.register(FORM, token).subscribe({
                    next: (next) => {
                        that.changes = false;
                        that.validation = { user: {}, company: {} };
                        that.saving = false;
                        that.successState = true;
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                        }, 1);
                    },
                    error: (error) => {
                        if (error.error.error && error.error.error == 'captcha_verification') {
                            that.validation = {
                                user: {},
                                company: {},
                                captcha: 'generic_error_captcha_score'
                            };
                            return;
                        }
                        that.validation = { user: {}, company: {} };
                        if (error.error.error && error.error.error == 'vat_in_fenavian') {
                            that.failureState = true;
                            return;
                        }
                        that.validation.user = error.error.details.user || {};
                        that.validation.company = error.error.details.company || {};
                        that.saving = false;
                    }
                });
            });
        });
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
