import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { TranslatorService } from './translator.service';
import { ResourceService } from './resource.service';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    params: any;
    showNewsletter$ = new BehaviorSubject<boolean>(false);
    showCaptcha$ = new BehaviorSubject<boolean>(false);
    adminCompaniesToAccept$ = new BehaviorSubject<number>(null);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private TranslatorService: TranslatorService,
        private ResourceService: ResourceService,
        private DefaultService: DefaultService
    ) {
        route.queryParams.subscribe((next: any) => {
            this.params = next;
        });
    }

    public getShowNewsletter() {
        return this.showNewsletter$.asObservable();
    }

    public setShowNewsletter(value) {
        this.showNewsletter$.next(value);
    }

    public getShowCaptcha() {
        return this.showCaptcha$.asObservable();
    }

    public setShowCaptcha(value) {
        if (value) {
            document.body.classList.add('show-captcha');
        } else document.body.classList.remove('show-captcha');
        this.showCaptcha$.next(value);
    }

    public getParam(param: string): string {
        if (!param || !this.params[param]) {
            return '';
        } else return this.params[param];
    }
    public addParam(param: string, value: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: value
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    public removeParam(param: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    // object is empty or only empty keys (null | undefined | '')
    public objectIsEmpty(object: any) {
        if (object.constructor != Object) return true;
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] == null || object[key] == undefined || object[key] == '') {
                    continue;
                } else return false;
            }
        }
        return true;
    }
    public downloadBlob(blob: Blob, filename: string) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        let blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }
    public copyToClipboard(val: string, successMessage: string) {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        if (successMessage) {
            this.toastr.info('', successMessage);
        }
    }

    public getUrl(url, page?, backTo?) {
        const lang = this.TranslatorService.getCurrentLanguage();
        const type = this.ResourceService.getSpeciesPartsCodes[0] || 'pork';
        let backToString = '';
        if (backTo) {
            backToString = `?backto=${backTo}`;
        }
        if (page == 'home') {
            if (!url) {
                return `/${lang}/${localStorage.getItem('indexType') || type}${backToString}`;
            } else return `/${lang}/${url}/${localStorage.getItem('indexType') || type}${backToString}`;
        }

        if (!url) {
            return `/${lang}${backToString}`;
        } else return `/${lang}/${url}${backToString}`;
    }

    public toTop() {
        window.scrollTo(0, 0);
    }

    public isDefined(item) {
        return item != undefined && item != null;
    }

    public getAdminCompaniesToAccept(amount?: number) {
        if (amount !== undefined) {
            this.adminCompaniesToAccept$.next(amount);
            return;
        }
        this.DefaultService.companiesGetAllCompanies(
            0,
            99,
            undefined,
            undefined,
            undefined,
            undefined,
            [2],
            undefined
        ).subscribe((next: any) => {
            this.adminCompaniesToAccept$.next(next?.rows);
        });
    }

    public getAdminCompaniesToAcceptObservable() {
        return this.adminCompaniesToAccept$.asObservable();
    }
}

// functions

export function memo<T extends Function>(fnToMemoize: T): T {
    let prevArgs = [{}];
    let result;

    return function (...newArgs) {
        if (hasDifferentArgs(prevArgs, newArgs)) {
            result = fnToMemoize(...newArgs);
            prevArgs = newArgs;
        }
        return result;
    } as any;
}

function hasDifferentArgs(prev: unknown[], next: unknown[]) {
    if (prev.length !== next.length) return true;
    for (let i = 0; i < prev.length; i++) {
        if (!Object.is(prev[i], next[i])) return true;
    }
    return false;
}
