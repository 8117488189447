import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { ConfigService } from './config.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    constructor(
        private DefaultService: DefaultService,
        private ConfigService: ConfigService
    ) {}
    private languages$ = new BehaviorSubject<any>(null);
    private federations$ = new BehaviorSubject<any>(null);
    private weekInfo$ = new BehaviorSubject<any>(null);
    private speciesParts$ = new BehaviorSubject<any>([]);

    public init(): Observable<any>[] {
        const observables$: Observable<any>[] = [of(null)];

        const languages$ = this.DefaultService.resourcesGetLanguages().pipe(
            tap((next) => {
                this.languages$.next(
                    next.map((item: any) => {
                        return { title: item.label, value: item.code };
                    })
                );
            })
        );
        observables$.push(languages$);

        const federations$ = this.DefaultService.resourcesGetAllFederations().pipe(
            tap((next) => {
                this.federations$.next(
                    next.map((item) => {
                        return { title: item.name, value: item.id, ...item };
                    })
                );
            })
        );
        observables$.push(federations$);

        const weekInfo$ = this.DefaultService.resourcesGetPrivateWeekInfo().pipe(
            tap((next) => {
                this.weekInfo$.next(next);
            })
        );
        observables$.push(weekInfo$);

        const speciesParts$ = this.DefaultService.resourcesGetAllSpeciesParts().pipe(
            tap((next) => {
                this.speciesParts$.next(next);
            })
        );
        observables$.push(speciesParts$);

        return observables$;
    }

    public initPublic(): Observable<any>[] {
        const observables$: Observable<any>[] = [of(null)];

        const languages$ = this.DefaultService.resourcesGetLanguages().pipe(
            tap((next) => {
                this.languages$.next(
                    next.map((item: any) => {
                        return { title: item.label, value: item.code };
                    })
                );
            })
        );
        observables$.push(languages$);

        const federations$ = this.DefaultService.resourcesGetAllFederations().pipe(
            tap((next) => {
                this.federations$.next(
                    next.map((item) => {
                        return { title: item.name, value: item.id, ...item };
                    })
                );
            })
        );
        observables$.push(federations$);

        const weekInfo$ = this.DefaultService.resourcesGetPublicWeekinfo().pipe(
            tap((next) => {
                this.weekInfo$.next(next);
            })
        );
        observables$.push(weekInfo$);

        const speciesParts$ = this.DefaultService.resourcesGetAllSpeciesParts().pipe(
            tap((next) => {
                this.speciesParts$.next(next);
            })
        );
        observables$.push(speciesParts$);

        return observables$;
    }

    public getLanguages() {
        return [...this.languages$.getValue()];
    }

    public getWeekInfo() {
        return this.weekInfo$.getValue();
    }

    public getSpeciesParts() {
        return this.speciesParts$.getValue();
    }

    public getSpeciesPartsCodes() {
        // return ['pork', 'beef'];
        return this.speciesParts$.getValue().map((item) => {
            return item.code;
        });
    }

    public getLanguageByCode(code) {
        return this.getLanguages()[
            this.getLanguages()
                .map((item) => {
                    return item.value;
                })
                .indexOf(code)
        ];
    }

    public getFederations() {
        return [...this.federations$.getValue()];
    }

    public getUserFederations(user) {
        const federationIds = user['federationIds'];
        if (federationIds && federationIds.length) {
            return this.federations$.getValue().filter((item) => {
                return federationIds.indexOf(item.id) != -1;
            });
        } else return [];
    }

    public getLocale() {
        return 'nl';
    }

    public getDayLabels() {
        return { su: 'Zo', mo: 'Ma', tu: 'Di', we: 'Wo', th: 'Do', fr: 'Vr', sa: 'Za' };
    }
}
