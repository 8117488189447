<div class="container page">

    <div class="panel position-relative hasState">
            <div class="success-state">
                <div class="mb-s">
                    <div class="title">{{'subscriptionconfirmed_title' | translate}}</div>
                </div>

                <div class="descr">
                    {{'subscriptionconfirmed_descr' | translate}}
                </div>

                <button [routerLink]="[HelpersService.getUrl('', 'home')]" class="button-link mt-xxxs"><span style="font-size: 20px;">{{'subscriptionconfirmed_next' | translate}}</span></button>
            </div>
            <div class="c"></div>
            <img src="/assets/img/icons/round-check.svg" alt="" class="c-check">

    </div>

</div>