<div class="container page">
    <h1>{{'contact_title' | translate}}</h1>
    <div class="panel position-relative" [class.hasState]="successState">

        <ng-container *ngIf="!successState">
            <div class="form">
                <!-- name -->
                <div class="row row-form">
                    <div class="col-md-5">
                        <app-field-text 
                            [model]="formSTR.name" 
                            (modelChange)="formSTR.name = $event"
                            (enter)="false"
                            typeClass="contact"
                            [error]="validation.name"
                            label="{{'contact_name' | translate}}"
                            placeholder="">
                        </app-field-text>
                    </div>
                </div>
                <!-- email -->
                <div class="row row-form">
                    <div class="col-md-7">
                        <app-field-text 
                            [model]="formSTR.email" 
                            (modelChange)="formSTR.email = $event"
                            (enter)="false"
                            typeClass="contact"
                            [error]="validation.email"
                            type="email"
                            label="{{'contact_email' | translate}}"
                            placeholder="">
                        </app-field-text>
                    </div>
                </div>
                <!-- message -->
                <div class="row row-form">
                    <div class="col-md-12">
                        <app-field-textarea label="{{'contact_message' | translate}}" [error]="validation.message" [error]="validation.message" [placeholder]="''" [model]="formSTR.message" (modelChange)="formSTR.message = $event;"></app-field-textarea>
                    </div>
                </div>

                <span *ngIf="validation.captcha" class="error-field-display--lone" style="margin-top: 6px;">{{validation.captcha | translate}}</span>
                <button class="button green mt-s button--spacious" (click)="submit()" [class.disabled]="saving">{{'contact_submit' | translate}}</button>
            </div>
        </ng-container>

        <ng-container *ngIf="successState">
            <!-- success -->
            <div class="success-state opacity-hide" [class.opacity-show]="successState">
                <div class="mb-s">
                    <div class="title">{{'contact_success_title' | translate}}</div>
                    <!-- <div class="subtitle">{{'contact_success_subtitle' | translate}}</div> -->
                </div>
            
                <div class="descr mb-s">
                    {{'contact_success_descr' | translate}}
                </div>

                <div>
                    <button [routerLink]="[this.HelpersService.getUrl('', 'home')]" class="button green button--spacious justify-content-center"><span>{{'contact_to_home' | translate}}</span></button>
                </div>
            </div>
            <div class="c"></div>
            <img src="/assets/img/icons/round-check.svg" alt="" class="c-check">
        </ng-container>

    </div>
</div>