<div class="container page admin-subscriptions">

    <!-- title row -->
    <div class="row">
        <div class="col-12"><h1>{{'adminsubscriptions_title' | translate}}</h1></div>
    </div>

    <div class="panel position-relative" id="items">

        <div class="search-bar-wrap">
            <div class="d-inline-flex search-bar">
                <input 
                    class="flex-grow-1"
                    [(ngModel)]="SEARCH.term" 
                    (keyup.enter)="!tableLoading && search()"
                    placeholder="{{'adminsubscriptions_search_placeholder' | translate}}"
                />
                <button class="button text-center justify-content-center" (click)="search()" [class.disabled]="tableLoading"><i class="im-icon im-icon-search"></i></button>
            </div>
            <button *ngIf="HelpersService.getParam('search')" (click)="search(true)" class="button-link reset-search">{{'misc_reset_search' | translate}}</button>
        </div>

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">
            <div class="table-scroll-- table-wrap">
                <app-table
                    [heads]="tableHeads" 
                    [data]="items" 
                    [sort]="SORT"
                    [RPP]="RPP"
                    [startRow]="startRow"
                    [totalRows]="totalRows"
                    [loading]="tableLoading"
                    [emptyState]="'adminsubscriptions_emptystate' | translate"
                    (sortChange)="clickSort($event.code, $event.dir)"
                    (startRowChange)="changeStartRow($event)"
                    [exportLabel]="'adminsubscriptions_export_label' | translate"
                    [exportBusy]="exportBusy"
                    (clickExport)="export()"
                ></app-table>
            </div>
        </ng-container>

    </div>

</div>