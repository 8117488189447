<div class="field field-text" [ngClass]="typeClass">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div class="d-flex align-items-center w-100 position-relative app-tooltip-wrap" [class.disabled-wrap]="disabled">
        <input 
            [class.error-field]="error || errorTooltip"
            [type]="getType()"
            class="flex-grow-1"
            [(ngModel)]="localModel" 
            (ngModelChange)="changes()"
            (blur)="onBlur($event)"
            (focus)="emitFocus.emit()"
            (keyup.enter)="onBlur(null); enter.emit()"
            [placeholder]="placeholder"
            [readonly]="disabled"
            [prefix]="maskPrefix"
            [mask]="mask"
            [showMaskTyped]="true"
            [autocomplete]="autocomplete"
            placeHolderCharacter="_"
        />
        <button *ngIf="type == 'password' && localModel && localModel.length" (click)="togglePwd = !togglePwd" tabindex="-1" class="toggle-show button-link"><ng-container *ngIf="!togglePwd">{{'fieldtext_show_pwd' | translate}}</ng-container><ng-container *ngIf="togglePwd">{{'fieldtext_hide_pwd' | translate}}</ng-container></button>
        <span class="after-string passive-gray" *ngIf="afterString">{{afterString | display}}</span>
        <div *ngIf="errorTooltip" class="app-tooltip app-tooltip-left-nowrap">{{errorTooltip}}</div>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>