import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';

@Component({
    selector: 'app-subscription-confirmed',
    templateUrl: './subscription-confirmed.component.html',
    styleUrls: ['./subscription-confirmed.component.scss']
})
export class SubscriptionConfirmedComponent implements OnInit {
    constructor(
        public HelpersService: HelpersService,
        public Router: Router
    ) {}

    ngOnInit(): void {}
}
