<div class="container page register">

    <!-- title -->
    <div [class.opacity-show]="!successState && !failureState" *ngIf="!successState && !failureState" class="opacity-hide d-flex justify-content-between align-items-end">
        <div><h1>{{'register_title_public' | translate}} <span class="light">{{'register_title_after_public' | translate}}</span></h1></div>
    </div>

    <div class="panel position-relative" [class.hasState]="successState || failureState">
        <!-- step 1 -->
        <div *ngIf="!successState && !failureState">
            
            <app-register-form
                [FORM]="formSTR"
                [validation]="validation"
                formType="register"
            ></app-register-form>

            <span *ngIf="validation.captcha" class="error-field-display--lone" style="margin-top: 6px;">{{validation.captcha | translate}}</span>

        </div>

        <div *ngIf="!successState && !failureState" class="sep-h mt-xl" style="margin-bottom: 20px;"></div>

        <div *ngIf="!successState && !failureState" class="d-flex align-items-center button-row">
            <button [class.disabled]="saving" (click)="submit()" class="button green button--spacious justify-content-center"><span>{{'register_next' | translate}}</span></button>
            <button [routerLink]="[this.HelpersService.getUrl('', 'home')]" class="button-link ml-s fontsize-l">{{'register_cancel_btn' | translate}}</button>
        </div>

        <!-- success -->
        <ng-container *ngIf="successState">
            <div class="success-state opacity-hide" [class.opacity-show]="successState">
                <div class="mb-s">
                    <div class="title">{{'register_success_title' | translate}}</div>
                    <div class="subtitle">{{'register_success_subtitle' | translate}}</div>
                </div>
            
                <div class="descr">
                    {{'register_success_descr' | translate}}
                </div>
            </div>
            <div class="c"></div>
            <img src="/assets/img/icons/round-check.svg" alt="" class="c-check">
        </ng-container>

        <!-- failure -->
        <ng-container *ngIf="failureState">
            <div class="failure-state opacity-hide" [class.opacity-show]="failureState">
                <div class="mb-s">
                    <div class="title">{{'register_failure_title' | translate}}</div>
                    <div class="subtitle">{{'register_failure_subtitle' | translate}}</div>
                </div>
            
                <div class="descr">
                    {{'register_failure_descr' | translate}}
                </div>
            </div>
            <div class="c c-red"></div>
            <img src="/assets/img/icons/round-x.svg" alt="" class="c-check">
        </ng-container>

    </div>

</div>