<div class="c"></div>
<img src="/assets/img/icons/round-check.svg" alt="" class="c-check">

<div class="wrap">

    <div class="mb-s">
        <div class="title">{{'registersuccess_title' | translate}}</div>
        <div class="subtitle">{{'registersuccess_subtitle' | translate}}</div>
    </div>

    <div class="descr">
        {{'registersuccess_descr' | translate}}
    </div>

    <button *ngIf="allowInputIndex" (click)="toNewIndex()" style="margin-top: 22px;" class="button green w-100 text-center button--spacious justify-content-center" [class.disabled]="false"><span>{{'registersuccess_register_index_btn' | translate}}</span><i class="im-icon im-icon-arrow-right"></i></button>
    <div *ngIf="allowInputIndex" class="or-line d-flex align-items-center justify-content-center"><span class="line"></span><span class="or">{{'misc_or' | translate}}</span></div>
    <button (click)="toHome()" style="margin-top: 22px;" class="button green--light w-100 text-center button--spacious justify-content-center"><span>{{'registersuccess_to_index_form_btn' | translate}}</span><i class="im-icon im-icon-arrow-right"></i></button>

</div>