import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../services/resource.service';
import { HelpersService } from '../../services/helpers.service';
import { SpeciesService } from '../../services/species.service';
import { AuthenticationService } from '../../services/authentication.service';
import { forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../../utils/api';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-parts',
    templateUrl: './parts.component.html',
    styleUrls: ['./parts.component.scss']
})
export class PartsComponent implements OnInit {
    ready: boolean = false;
    formSTR: any = {};
    speciesParts: any[] = [];
    userActivities: any[] = [];
    speciesPartsStructured: any = {};
    validation: any = {};
    saving: boolean = false;
    changes: boolean = false;

    constructor(
        public TranslatePipe: TranslatePipe,
        private ModalService: NgbModal,
        private Router: Router,
        private toastr: ToastrService,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        public SpeciesService: SpeciesService,
        public AuthenticationService: AuthenticationService,
        public DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        const user: any = this.AuthenticationService.getUserValue();
        if (user['activities'] && user['activities'].length) {
            // ok
        } else {
            this.Router.navigate([this.HelpersService.getUrl('register-activities')]);
            return;
        }

        this.userActivities = user.activities || [];

        const observables$: Observable<any>[] = [of(null)];
        const speciesParts$ = this.SpeciesService.getAllSpeciesParts().pipe(
            tap((next) => {
                this.speciesParts = next;
            })
        );
        observables$.push(speciesParts$);
        const partIds$ = this.SpeciesService.getCompanySpeciesParts().pipe(
            tap((next: any) => {
                this.formSTR = next;
                this.userActivities.forEach((act) => {
                    if (!this.formSTR[act.species]) {
                        this.formSTR[act.species] = [];
                    }
                });
            })
        );
        observables$.push(partIds$);

        forkJoin(observables$).subscribe((next) => {
            this.speciesPartsStructured = this.SpeciesService.getSpeciesPartsStructured(this.speciesParts);

            setTimeout(() => {
                this.ready = true;
            }, 400);
        });
    }

    getSpeciesParts() {
        return this.speciesParts.filter((item) => {
            return this.hasSector(item.code);
        });
    }

    hasSector(code) {
        return (
            this.userActivities
                .map((item) => {
                    return item.species;
                })
                .indexOf(code) != -1
        );
    }

    submit() {
        this.saving = true;

        // partIds
        this.SpeciesService.saveSpeciesParts(this.formSTR).subscribe({
            next: (next) => {
                this.validation = {};
                this.saving = false;
                this.changes = false;
                this.toastr.success(
                    this.TranslatePipe.transform('toast_save_parts_success_msg'),
                    this.TranslatePipe.transform('toast_save_parts_success_title')
                );
            },
            error: (error) => {
                this.validation = error.error.details;
                this.saving = false;
            }
        });
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
