import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../utils/pipes';
import { formatTsPipe } from '../../utils/pipes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../services/resource.service';
import { HelpersService } from '../../services/helpers.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultService } from '../../utils/api';

@Component({
    selector: 'app-registration-history',
    templateUrl: './registration-history.component.html',
    styleUrls: ['./registration-history.component.scss']
})
export class RegistrationHistoryComponent implements OnInit {
    history: any[] = null;

    RPP: number = 20;
    startRow: number = 0;
    totalRows: number = null;
    tableLoading: boolean = true;

    TranslatePipe = inject(TranslatePipe);

    tableHeads: any = [
        { name: this.TranslatePipe.transform('registrationhistory_year'), code: 'year', sortable: false },
        { name: this.TranslatePipe.transform('registrationhistory_week_nr'), code: 'weekNr', sortable: false },
        { name: this.TranslatePipe.transform('registrationhistory_name'), code: 'editedBy', sortable: false },
        { name: this.TranslatePipe.transform('registrationhistory_edited_on'), code: 'editedOn', sortable: false }
    ];

    constructor(
        public formatTsPipe: formatTsPipe,
        private DefaultService: DefaultService,
        private ModalService: NgbModal,
        private Router: Router,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.getHistory();
    }

    getHistory() {
        const searchSTR = this.getSearchQuery();
        this.tableLoading = true;
        this.DefaultService.dataInputGetDataInputHistoryList(searchSTR.rRP, searchSTR.startRow).subscribe(
            (next: any) => {
                if (this.startRow && this.startRow >= next.rows) {
                    this.resetStartRow();
                    return;
                }
                this.history = next.data.map((item) => {
                    const actions = [];
                    return {
                        ...item,
                        editedBy: item.name,
                        editedOn: `${this.formatTsPipe.transform(
                            item.editTS,
                            'D MMM YYYY'
                        )} ${this.TranslatePipe.transform('misc_date_at')} ${this.formatTsPipe.transform(
                            item.editTS,
                            'HH:mm'
                        )}`
                    };
                });
                this.tableLoading = false;
                this.totalRows = next.rows;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        );
    }

    tableClick(item: any, head: string) {
        if (item.editable) {
            this.Router.navigate([this.HelpersService.getUrl('new')]);
        } else this.Router.navigate([this.HelpersService.getUrl(`registration-history/${item.year}/${item.weekNr}`)]);
    }

    resetStartRow() {
        this.startRow = 0;
        this.getHistory();
    }

    getSearchQuery() {
        return {
            startRow: this.startRow,
            rRP: this.RPP
        };
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.getHistory();
    }
}
