<div class="scroll-xx">
    <table class="table">
        <thead>
        <tr>
            <ng-container *ngFor="let head of heads">
                <th [ngStyle]="{'width' : head.width ? head.width : '' }" [class.th--actions]="head.code === 'actions'" [class.sortable]="head.sortable" (click)="head.sortable && clickSort(head)"><div class="position-relative" [ngClass]="getSortingClass(head)">{{head.name}}</div></th>
            </ng-container>
        </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of data; index as $i">
                <tr [class.active]="item.trActive" [class.clickable]="clickable">
                    <ng-container *ngFor="let head of heads">
                        <td (click)="clickData.emit({item: item, head: head.code})" class="saving--pre" [class.saving]="loading" [class.shrink]="item[head.code] && item[head.code].type === 'actions'" [class.td--actions]="item[head.code] && item[head.code].type === 'actions'" [class.no-wrap]="item[head.code] && item[head.code].stamps">

                            <!-- default -->
                            <ng-container *ngIf="!item[head.code] || !item[head.code].type">{{item[head.code] | display}}</ng-container>

                            <!-- default value -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'default'">
                                <span [class.mr-xxs]="item[head.code].value">{{item[head.code].value}}</span>
                            </ng-container>

                            <!-- arrayTooltip -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'arrayTooltip'">
                                <app-array-tooltip-display [items]="item[head.code].items"></app-array-tooltip-display>
                            </ng-container>

                            <!-- add stamps -->
                            <ng-container *ngIf="item[head.code] && item[head.code].stamps">
                                <div class="d-inline-block">
                                    <span *ngFor="let stamp of item[head.code].stamps" class="stamp mr-xxs" [class]="stamp.classList">
                                        {{stamp.value}}
                                    </span>
                                </div>
                            </ng-container>

                            <!-- titleStampArray -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type == 'titleStampArray'">
                                <div class="title_stamp_array_wrap">
                                    <div class="title_stamp_array" *ngFor="let title of item[head.code].titles">
                                        <div class="title" [ngStyle]="{'color': title.color || 'lightgray'}">{{title.value}}</div>
                                        <div class="stamps">
                                            <div class="d-inline-block">
                                                <span *ngFor="let stamp of title.stamps" class="stamp mr-xxs" [class]="stamp.classList">{{stamp.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="!item[head.code].titles?.length">-</ng-container>
                            </ng-container>

                            <!-- default black -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'black'"><span class="text-color">{{item[head.code].value | display}}</span></ng-container>

                            <!-- titleSubtitle -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'titleSubtitle'"><div class="d-flex flex-column"><span class="text-color">{{item[head.code].title}}</span><span class="fontsize-s" style="margin-top: 3px;">{{item[head.code].subtitle}}</span></div></ng-container>

                            <!-- title -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'title'"><span class="text-color">{{item[head.code].title}}</span></ng-container>

                            <!-- avatar -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'avatar'"><app-avatar [user]="item[head.code].user" [image]="item[head.code].image" [title]="item[head.code].title" [subtitle]="item[head.code].subtitle" [size]="40" [fontSize]="14"></app-avatar></ng-container>
                            
                            <!-- ts -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'ts'"><span style="white-space: nowrap;">{{item[head.code].ts | formatTs: item[head.code].format}}</span></ng-container>

                            <!-- actions -->
                            <ng-container *ngIf="item[head.code] && item[head.code].type === 'actions'">
                                <div *ngIf="item[head.code].actions && item[head.code].actions.length" class="action-button d-inline-flex align-items-center actions position-relative" (click)="helper.actionIndex == $i ? setActionIndex(null) : setActionIndex($i); $event.stopPropagation();" appClickOutside (clickOutside)="setActionIndex(null); clickOutsideActions()">
                                    <i class="im-icon im-icon-actions"></i>
                                    <app-popover [open]="helper.actionIndex === $i" positionClass="left" (click)="setActionIndex(null); $event.stopPropagation();">
                                        <ul (click)="setActionIndex(null)">
                                            <li class="position-relative" *ngFor="let action of item[head.code].actions" appClickOutside (clickOutside)="popoverHelper = null" (click)="action.confirmDelete ? (popoverHelper = 'delete_' + $i) : clickAction.emit({item: item, action: action.code});" [ngClass]="action.class || ''">
                                                <i *ngIf="action.icon" class="im-icon im-icon-{{action.icon}}"></i> 
                                                {{action.name}}
                                                <app-confirm-delete *ngIf="action.confirmDelete" (confirm)="clickAction.emit({item: item, action: action.code}); dismissPopover(); setActionIndex(null);" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete_' + $i" positionClass="left"></app-confirm-delete>
                                            </li>
                                        </ul>
                                    </app-popover>
                                </div>
                            </ng-container>
                            
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
        <!-- shimmer -->
        <!-- <tbody *ngIf="loading && shimmerTypes">
            <ng-container *ngFor="let item of shimmerRows">
                <tr [class.clickable]="clickable">
                    <ng-container *ngFor="let type of shimmerTypes">
                        <td [class.shrink]="type === 'actions'">

                            <app-shimmer [type]="type"></app-shimmer>
                            
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody> -->
    </table>
</div>
<!-- spinner -->
<div *ngIf="loading && !shimmerTypes" class="mt-m">
    <app-spinner></app-spinner>
</div>
<!-- emptystate -->
<div *ngIf="!loading && data && !data.length && emptyState" class="pl-xs pr-xs mt-s">
    <app-empty-state [value]="emptyState"></app-empty-state>
</div>
<!-- pagination / export -->
<div *ngIf="data && data.length" class="row mt-l">
    <div class="col-md-4">
        <div class="d-flex justify-content-end pl-s">
            <!--  -->
        </div>
    </div>
    <div class="col-md-4">
        <div class="d-flex justify-content-center" *ngIf="totalRows > RPP">
            <app-pagination 
                [RPP]="RPP"
                [startRow]="startRow"
                [totalRows]="totalRows"
                (startRowChange)="startRowChange.emit($event)">
            </app-pagination>
        </div>
    </div>
    <div class="col-md-4 d-flex flex-row align-items-center">
        <div *ngIf="exportLabel" class="d-flex w-100 justify-content-end pr-s">
            <button (click)="clickExport.emit()" [class.disabled]="exportBusy" class="button-link fontsize-m export-btn">
                <i *ngIf="!exportBusy" class="im-icon im-icon-download"></i>
                <!-- *ngIf="exportBusy" -->
                <app-inline-spinner *ngIf="exportBusy" class="mr-1"></app-inline-spinner>
                <span>{{exportLabel}}</span>
            </button>
        </div>
    </div>
</div>