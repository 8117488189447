import { Injectable } from '@angular/core';
import { HelpersService } from './helpers.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(public HelpersService: HelpersService) {}

    getCleanSort(SORT: any, initialSort: any) {
        if (this.HelpersService.objectIsEmpty(SORT) || (SORT.code == initialSort.code && SORT.dir == initialSort.dir)) {
            return;
        } else return SORT;
    }

    getSort(code: string, SORT: any, dir?: string) {
        let result = SORT;
        if (SORT.code === code) {
            result.dir = SORT.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: code, dir: 'asc' };
        }
        if (dir) result.dir = dir;
        return result;
    }

    getSearchBody(SEARCH: any, SORT: any, RPP: number, startRow: number) {
        const result = {
            ...Object.assign({}, SEARCH),
            RPP: RPP,
            startRow: startRow,
            orderBy: [`${SORT.code} ${SORT.dir}`]
        };
        return result;
    }

    getCleanSearch(SEARCH: any) {
        const searchParams = Object.assign({}, SEARCH);
        for (const key in searchParams) {
            const value = searchParams[key];
            if (value === null || value === undefined || value === '') {
                delete searchParams[key];
            }
        }
        // search
        if (this.HelpersService.objectIsEmpty(searchParams)) {
            return;
        }
        return searchParams;
    }
}
