import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '../../../services/helpers.service';

@Component({
    selector: 'app-new-index-success',
    templateUrl: './new-index-success.component.html',
    styleUrls: ['./new-index-success.component.scss']
})
export class NewIndexSuccessComponent implements OnInit {
    constructor(public ActiveModal: NgbActiveModal, public HelpersService: HelpersService) {}

    ngOnInit(): void {}
}
