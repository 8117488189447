@if (ready && user.company.type !== 'gov') {
    <!-- to accept -->
    <div class="app-alert" *ngIf="company?.company?.status === 2"><div class="container">
        <div class="d-flex">
            <span>{{'admincompany_status_2_alert' | translate}}</span>
            <div class="d-flex buttons">
                <button (click)="setStatus(1)" class="button-link">{{'admincompany_accept' | translate}}</button>/
                <button (click)="setStatus(4)" class="button-link">{{'admincompany_decline' | translate}}</button>
            </div>
        </div>
    </div></div>
    <!-- inactive -->
    <div class="app-alert passive" *ngIf="company?.company?.status === 3"><div class="container">
        <div class="d-flex">
            <span>{{'admincompany_status_3_alert' | translate}}</span>
            <div class="d-flex buttons">
                <button (click)="setStatus(1)" class="button-link">{{'admincompany_set_active_again' | translate}}</button>
            </div>
        </div>
    </div></div>
    <!-- rejected -->
    <div class="app-alert error" *ngIf="company?.company?.status === 4"><div class="container">
        <div class="d-flex">
            <span>{{'admincompany_status_4_alert' | translate}}</span>
            <div class="d-flex buttons">
                <button (click)="setStatus(2)" class="button-link">{{'admincompany_set_active' | translate}}</button>
            </div>
        </div>
    </div></div>
}

<div class="container page admincompany">

    <div *ngIf="!ready" class="mt-l mb-xxl">
        <app-spinner></app-spinner>
    </div>

    <ng-container *ngIf="ready">

        <!-- title -->
        <div class="d-flex justify-content-between align-items-end">
            <div class="d-flex align-items-center"><h1>{{company.company.company}}</h1> <span class="title-stamp" *ngIf="showStatus()">{{'status_' + company.company.status | translate}}</span></div>
        </div>

        <div class="panel position-relative">

            @if (user.company.type === 'gov') {
                <div class="select-table d-flex">
                    <ng-container *ngFor="let tab of tabs">
                        <button [class.active]="activeTab == tab" (click)="activeTab = tab">{{'admincompany_' + tab | translate}}</button>
                    </ng-container>
                </div>
            }
    
            <!-- tab registrations -->
            @if (user.company.type === 'gov' && activeTab === 'registrations') {

                <div class="search-bar-wrap">
                        <div class="d-inline-flex search-bar">
                            <app-field-date
                                [model]="SEARCH.dateFrom" 
                                (modelChange)="SEARCH.dateFrom = $event;"
                                (changed)="false && getHistory();"
                                [placeholder]="'misc_date_placeholder_dateFrom' | translate"
                            ></app-field-date>
                            <app-field-date
                                [model]="SEARCH.dateTo" 
                                (modelChange)="SEARCH.dateTo = $event;"
                                (changed)="false && getHistory();"
                                [placeholder]="'misc_date_placeholder_dateTo' | translate"
                            ></app-field-date>
                            <button class="button text-center justify-content-center" (click)="getHistory()" [class.disabled]="tableLoading"><i class="im-icon im-icon-search"></i></button>
                        </div>
                        <button *ngIf="SEARCH.dateFrom || SEARCH.dateTo" (click)="SEARCH.dateFrom = null; SEARCH.dateTo = null; getHistory()" class="button-link reset-search">{{'misc_reset_search' | translate}}</button>
                </div>

                <div class="table-scroll">
                    <app-table
                        [heads]="tableHeads" 
                        [data]="history" 
                        [sort]=""
                        [RPP]="RPP"
                        [startRow]="startRow"
                        [totalRows]="totalRows"
                        [loading]="tableLoading"
                        (clickData)="tableClick($event.item, $event.head)"
                        [emptyState]="'registrationhistory_emptystate' | translate"
                        (startRowChange)="changeStartRow($event)"
                        [exportLabel]="'Export'"
                        [exportBusy]="exportBusy"
                        (clickExport)="export()"
                        >
                    </app-table>
                </div>
            }
    
            <!-- tab company -->
            @if (user.company.type !== 'gov' || activeTab === 'company') {
                <app-register-form
                    [READONLYFORM]="company"
                    formType="profile"
                    (setInactive)="setStatus(3)"
                    [admin]="true"
                ></app-register-form>
            }
        </div>

    </ng-container>

</div>

<div class="container page" *ngIf="company?.company?.status === 1 && user.company.type !== 'gov'" style="padding-top: 0 !important; margin-top: -32px;">
    <div class="d-flex justify-content-end w-100">
        <button style="font-size: 18px; margin-left: 12px; text-decoration: underline;" (click)="setStatus(3)" class="button-link">{{'admincompany_set_inactive' | translate}}</button>
    </div>
</div>