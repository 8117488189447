import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../models/common';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private config: Config = {
        appName: 'Meatindex',
        apiUrl: 'api/v1',
        avatarExtendInitials: false,
        appOverviewUrl: null,
        hasDarkLogo: true,
        appCode: null,
        homeRoute: null,
        isIE: false
    };
    private darkMode$ = new BehaviorSubject<boolean>(null);

    constructor(private TitleService: Title, private Router: Router, public Route: ActivatedRoute) {
        const darkMode = parseInt(localStorage.getItem('darkMode'));
        if (
            (darkMode === undefined || darkMode === null) &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            // this.setDarkMode();
        } else if (darkMode) {
            // this.setDarkMode();
        } else {
            // this.unsetDarkMode();
        }
        var ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE ') != -1 || ua.indexOf('Trident/') != -1) {
            this.config.isIE = true;
        }
    }

    public getConfig(): Config {
        return this.config;
    }

    public setDarkMode() {
        this.darkMode$.next(true);
        localStorage.setItem('darkMode', '1');
    }

    public unsetDarkMode() {
        this.darkMode$.next(false);
        localStorage.setItem('darkMode', '0');
    }

    public toggleDarkMode() {
        const newValue = !this.darkMode$.getValue();
        newValue ? this.setDarkMode() : this.unsetDarkMode();
    }

    public getDarkMode() {
        return this.darkMode$;
    }
}
