<div class="container page">
    <div class="d-flex justify-content-between align-items-end">
        <div><h1>{{'parts_title' | translate}}</h1></div>
    </div>
    <div class="panel">

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">

            <div>
                
                <div class="categories">
                    <ng-container *ngFor="let species of getSpeciesParts(); index as $i">
                        <h2 class="mt-xl mb-xxs" [class.mt-none]="$i == 0">{{'register_' + species.code + '_sector' | translate}}</h2>
                        <ng-container *ngFor="let parts of speciesPartsStructured[species.code]; index as $j">
                            <div class="row row-form mw-800-px" style="margin-bottom: 0;">
                                <div class="col-md-12 mb-0">
                                    <app-field-checkboxes 
                                        class="d-block"
                                        label=""
                                        [model]="formSTR[species.code]" 
                                        (modelChange)="formSTR[species.code] = $event; changes = true"
                                        [options]="parts"
                                        [optionWidth]=""
                                        col="md"
                                        [optionColumnWidth]="4">
                                    </app-field-checkboxes>
                                </div>
                            </div>
                        </ng-container>
                        <span *ngIf="validation[species.code]" class="error-field-display--lone" style="">{{validation[species.code]}}</span>
                    </ng-container>
                </div>

            </div>

            <div class="sep-h mt-xl" style="margin-bottom: 20px;"></div>

            <div class="d-flex align-items-center">
                <button (click)="submit()" [class.disabled]="saving || !changes" class="button green button--spacious justify-content-center"><span>{{'parts_submit_btn' | translate}}</span></button>
            </div>

        </ng-container>

    </div>
</div>