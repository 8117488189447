<i class="im-icon im-icon-x-light" (click)="ActiveModal.dismiss()"></i>

<div class="mb-xs">
    <div class="title">{{'adduser_title' | translate}}</div>
</div>

<div class="descr mb-m">
    {{'adduser_descr' | translate}}
</div>

<div class="form">

    <div class="row row-form">
        <div class="col-md-12">
            <app-field-radios 
                class="d-block"
                label="{{'adduser_language' | translate}}"
                [model]="formSTR.language" 
                (modelChange)="formSTR.language = $event"
                [error]="validation.language"
                [options]="languageOptions"
                [optionWidth]=""
                [optionColumnWidth]="">
            </app-field-radios>
        </div>
    </div>
    
    <div class="row row-form">
        <div class="col-md-12">
            <div style="max-width: 380px;">
                <app-field-text 
                    [model]="formSTR.email" 
                    (modelChange)="formSTR.email = $event"
                    [error]="validation.email"
                    type="email"
                    label="{{'adduser_contact_email' | translate}}"
                    placeholder="">
                </app-field-text>
            </div>
        </div>
    </div>

    <!-- <div class="sep-h mt-xl" style="margin-bottom: 20px;"></div> -->

    <div class="d-flex align-items-center mt-m button-row">
        <button (click)="submit()" [class.disabled]="saving" class="button green button--spacious justify-content-center"><span>{{'adduser_submit_btn' | translate}}</span></button>
        <button (click)="ActiveModal.dismiss()" class="button-link ml-s fontsize-l">{{'adduser_cancel_btn' | translate}}</button>
    </div>

</div>