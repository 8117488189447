<div class="container page registration-history">

    <!-- title -->
    <div class="d-flex justify-content-between align-items-end">
        <div><h1>{{'registrationhistory_title' | translate}}</h1></div>
    </div>

    <div class="panel position-relative">

        <!-- <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div> -->

        <ng-container *ngIf="true">
                
            <div class="table-scroll">
                <app-table
                    [heads]="tableHeads" 
                    [data]="history" 
                    [sort]=""
                    [RPP]="RPP"
                    [startRow]="startRow"
                    [totalRows]="totalRows"
                    [loading]="tableLoading"
                    (clickData)="tableClick($event.item, $event.head)"
                    [emptyState]="'registrationhistory_emptystate' | translate"
                    (startRowChange)="changeStartRow($event)"
                    >
                </app-table>
            </div>

        </ng-container>

    </div>

</div>