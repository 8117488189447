import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-field-password-check',
    templateUrl: './field-password-check.component.html',
    styleUrls: ['./field-password-check.component.scss']
})
export class FieldPasswordCheckComponent implements OnChanges {
    @Input() label?: string;
    @Input() error?: string;
    @Input() placeholder?: string;
    @Input() model: string;
    @Input() hideCheck: boolean = false;
    @Output() modelChange = new EventEmitter();
    @Output() validChange = new EventEmitter();
    @Output() enter = new EventEmitter();

    localModel: any = '';
    togglePwd: boolean = false;
    disabled: boolean = true;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {}

    emit($event) {
        setTimeout(() => {
            this.modelChange.emit(this.localModel);
            this.validChange.emit(this.allValid());
        }, 1);
    }

    getType() {
        if (this.togglePwd) {
            return 'text';
        } else return 'password';
    }

    valid(type) {
        if (!this.localModel) return false;
        switch (type) {
            case 'lower':
                return /[a-z]/.test(this.localModel);
            case 'upper':
                return /[A-Z]/.test(this.localModel);
            case 'number':
                return /\d/.test(this.localModel);
            case 'special':
                var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                return format.test(this.localModel);
            case 'amount':
                return this.localModel.length >= 8;
            default:
                return false;
        }
    }

    allValid() {
        return (
            this.valid('lower') &&
            this.valid('upper') &&
            this.valid('number') &&
            this.valid('special') &&
            this.valid('amount')
        );
    }
}
