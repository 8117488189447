<div class="field field-textarea">
    <span *ngIf="label" class="label">{{label}}</span>
    <textarea 
        [class.error-field]="error"
        [(ngModel)]="model"
        (input)="modelChange.emit($event.target['value'])"
        (keyup.enter)="enter.emit()"
        [placeholder]="placeholder"
    ></textarea>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>