<div class="container page home">
    <div *ngIf="!ready" class="spinner-wrap">
        <app-spinner></app-spinner>
    </div>

    <ng-container *ngIf="ready && monitor.weekData">
        <div class="row">
            <div class="col-md-12">
                <h1>{{'nav_slaughterhousemonitor' | translate}} {{monitor.weekData[0].current.year}} <span class="light" style="white-space: nowrap;">{{'home_week' | translate}} {{monitor.weekData[0].current.weekNr}}</span></h1>
                <div class="subtitle">{{'monitor_based_on_numbers_of' | translate}} {{weekInfo.previous.weekNr}}</div>
            </div>
        </div>
        <div class="select-table d-flex">
            <ng-container *ngFor="let code of speciesPartCodes">
                <button [class.active]="indexType == code" (click)="selectTable(code)">{{'home_' + code | translate}}</button>
            </ng-container>
        </div>

        <!-- DESKTOP -->
        <div class="monitor-desktop">
            <!-- weekly -->
            <h2>{{'monitor_weekly_statistics' | translate}}</h2>
            <table class="index">
                <tr>
                    <th style="width: 100%;">{{'monitor_description' | translate}}</th>
                    <th style="min-width: 170px;">{{'monitor_week' | translate}} {{monitor.weekData[0].current.weekNr}}</th>
                    <th style="min-width: 170px;">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previous.weekNr}}</th>
                    <th style="min-width: 170px;">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previous4.weekNr}}</th>
                    <th style="min-width: 170px;">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previousYear.weekNr}} {{monitor.weekData[0].previousYear.year}}</th>
                </tr>
                <!-- gemiddeld warm karkasgewicht -->
                <tr class="tr--part tr--first tr--last">
                    <td class="part">
                        <div class="d-flex align-items-center">
                            {{'monitor_descr_gemiddeld_warmgewicht' | translate}}
                        </div>
                    </td>
                    <td class="cat">
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].current.gemiddeld_warmgewicht)">{{monitor.weekData[0].current.gemiddeld_warmgewicht | abs | customNumber}} <span class="passive">{{'unit_kg' | translate}}</span></div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].current.gemiddeld_warmgewicht)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous.diff_gemiddeld_warmgewicht)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous.diff_gemiddeld_warmgewicht)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous.diff_gemiddeld_warmgewicht)}}"></i> <span>{{monitor.weekData[0].previous.diff_gemiddeld_warmgewicht | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_kg' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous.diff_gemiddeld_warmgewicht)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht)}}"></i> <span>{{monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_kg' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht)}}"></i> <span>{{monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_kg' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht)">-</div>
                    </td>
                </tr>
                <tr class="tr--spacer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <!-- totaal aantal slachtingen belgisch -->
                <tr class="tr--part tr--first tr--last">
                    <td class="part">
                        <div class="d-flex align-items-center">
                            {{'monitor_descr_aantal_dieren' | translate}}
                        </div>
                    </td>
                    <td class="cat">
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].current.aantal_dieren)">{{monitor.weekData[0].current.aantal_dieren | abs | customNumber}} <span class="passive">{{'unit_pieces' | translate}}</span></div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].current.aantal_dieren)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous.diff_aantal_dieren)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous.diff_aantal_dieren)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous.diff_aantal_dieren)}}"></i> <span>{{monitor.weekData[0].previous.diff_aantal_dieren | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous.diff_aantal_dieren)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous4.diff_aantal_dieren)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous4.diff_aantal_dieren)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous4.diff_aantal_dieren)}}"></i> <span>{{monitor.weekData[0].previous4.diff_aantal_dieren | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous4.diff_aantal_dieren)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previousYear.diff_aantal_dieren)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previousYear.diff_aantal_dieren)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previousYear.diff_aantal_dieren)}}"></i> <span>{{monitor.weekData[0].previousYear.diff_aantal_dieren | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previousYear.diff_aantal_dieren)">-</div>
                    </td>
                </tr>
                <tr class="tr--spacer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <!-- totaal aantal slachtingen niet belgisch -->
                <tr class="tr--part tr--first tr--last">
                    <td class="part">
                        <div class="d-flex align-items-center">
                            {{'monitor_descr_aantal_dieren_niet_be' | translate}}
                        </div>
                    </td>
                    <td class="cat">
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].current.aantal_dieren_niet_be)">{{monitor.weekData[0].current.aantal_dieren_niet_be | abs | customNumber}} <span class="passive">{{'unit_pieces' | translate}}</span></div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].current.aantal_dieren_niet_be)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous.diff_aantal_dieren_niet_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous.diff_aantal_dieren_niet_be)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous.diff_aantal_dieren_niet_be)}}"></i> <span>{{monitor.weekData[0].previous.diff_aantal_dieren_niet_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous.diff_aantal_dieren_niet_be)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous4.diff_aantal_dieren_niet_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous4.diff_aantal_dieren_niet_be)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous4.diff_aantal_dieren_niet_be)}}"></i> <span>{{monitor.weekData[0].previous4.diff_aantal_dieren_niet_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous4.diff_aantal_dieren_niet_be)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be)}}"></i> <span>{{monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be)">-</div>
                    </td>
                </tr>
                <tr class="tr--spacer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
            <!-- monthly -->
            <h2>{{'monitor_monthly_statistics' | translate}}</h2>
            <table class="index">
                <tr>
                    <th style="width: 100%;">{{'monitor_description' | translate}}</th>
                    <th style="min-width: 170px;">{{(monitor.monthData[0].current.year+'-'+(monitor.monthData[0].current.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM' | titlecase}}</th>
                    <th style="min-width: 170px;">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previous.year+'-'+(monitor.monthData[0].previous.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previous.year}}</th>
                    <th style="min-width: 170px;">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previous6.year+'-'+(monitor.monthData[0].previous6.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previous6.year}}</th>
                    <th style="min-width: 170px;">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previousYear.year+'-'+(monitor.monthData[0].previousYear.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previousYear.year}}</th>
                </tr>
                <!-- aantal zeugen -->
                <tr class="tr--part tr--first tr--last">
                    <td class="part">
                        <div class="d-flex align-items-center">
                            {{'monitor_descr_aantal_zeugen' | translate}}
                        </div>
                    </td>
                    <td class="cat">
                        <div *ngIf="helpersService.isDefined(monitor.monthData[0].current.aantal_zeugen)">{{monitor.monthData[0].current.aantal_zeugen | abs | customNumber}} <span class="passive">{{'unit_pieces' | translate}}</span></div>
                        <div *ngIf="!helpersService.isDefined(monitor.monthData[0].current.aantal_zeugen)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.monthData[0].previous.diff_aantal_zeugen)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previous.diff_aantal_zeugen)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previous.diff_aantal_zeugen)}}"></i> <span>{{monitor.monthData[0].previous.diff_aantal_zeugen | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previous.diff_aantal_zeugen)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.monthData[0].previous6.diff_aantal_zeugen)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previous6.diff_aantal_zeugen)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previous6.diff_aantal_zeugen)}}"></i> <span>{{monitor.monthData[0].previous6.diff_aantal_zeugen | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previous6.diff_aantal_zeugen)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.monthData[0].previousYear.diff_aantal_zeugen)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previousYear.diff_aantal_zeugen)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previousYear.diff_aantal_zeugen)}}"></i> <span>{{monitor.monthData[0].previousYear.diff_aantal_zeugen | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previousYear.diff_aantal_zeugen)">-</div>
                    </td>
                </tr>
                <tr class="tr--spacer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>

                <!-- aantal zeugen be -->
                <tr class="tr--part tr--first tr--last">
                    <td class="part">
                        <div class="d-flex align-items-center">
                            {{'monitor_descr_aantal_zeugen_be' | translate}}
                        </div>
                    </td>
                    <td class="cat">
                        <div *ngIf="helpersService.isDefined(monitor.monthData[0].current.aantal_zeugen_be)">{{monitor.monthData[0].current.aantal_zeugen_be | abs | customNumber}} <span class="passive">{{'unit_pieces' | translate}}</span></div>
                        <div *ngIf="!helpersService.isDefined(monitor.monthData[0].current.aantal_zeugen_be)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.monthData[0].previous.diff_aantal_zeugen_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previous.diff_aantal_zeugen_be)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previous.diff_aantal_zeugen_be)}}"></i> <span>{{monitor.monthData[0].previous.diff_aantal_zeugen_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previous.diff_aantal_zeugen_be)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.monthData[0].previous6.diff_aantal_zeugen_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previous6.diff_aantal_zeugen_be)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previous6.diff_aantal_zeugen_be)}}"></i> <span>{{monitor.monthData[0].previous6.diff_aantal_zeugen_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previous6.diff_aantal_zeugen_be)">-</div>
                    </td>
                    <td>
                        <div *ngIf="helpersService.isDefined(monitor.monthData[0].previousYear.diff_aantal_zeugen_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previousYear.diff_aantal_zeugen_be)">
                            <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previousYear.diff_aantal_zeugen_be)}}"></i> <span>{{monitor.monthData[0].previousYear.diff_aantal_zeugen_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                        </div>
                        <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previousYear.diff_aantal_zeugen_be)">-</div>
                    </td>
                </tr>
                <tr class="tr--spacer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>

            </table>
        </div>
        
        <div class="monitor-mobile">
            <!-- weekly -->
            <h2>{{'monitor_weekly_statistics' | translate}}</h2>
            <div class="index-panels">
                <!-- gemiddeld warm karkasgewicht -->
                <div class="index-panel">
                    <div class="title">{{'monitor_descr_gemiddeld_warmgewicht' | translate}}</div>
                    <div class="current">
                        <div class="wrap">
                            <div class="label">{{'monitor_week' | translate}} {{monitor.weekData[0].current.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].current.gemiddeld_warmgewicht)">{{monitor.weekData[0].current.gemiddeld_warmgewicht | abs | customNumber}} <span class="passive">{{'unit_kg' | translate}}</span></div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].current.gemiddeld_warmgewicht)">-</div>
                            </div>
                        </div>
                    </div>
                    <div class="previous">
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previous.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous.diff_gemiddeld_warmgewicht)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous.diff_gemiddeld_warmgewicht)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous.diff_gemiddeld_warmgewicht)}}"></i> <span>{{monitor.weekData[0].previous.diff_gemiddeld_warmgewicht | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_kg' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous.diff_gemiddeld_warmgewicht)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previous4.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht)}}"></i> <span>{{monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_kg' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous4.diff_gemiddeld_warmgewicht)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previousYear.weekNr}} {{monitor.weekData[0].previousYear.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht)}}"></i> <span>{{monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_kg' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previousYear.diff_gemiddeld_warmgewicht)">-</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- totaal aantal slachtingen belgisch -->
                <div class="index-panel">
                    <div class="title">{{'monitor_descr_aantal_dieren' | translate}}</div>
                    <div class="current">
                        <div class="wrap">
                            <div class="label">{{'monitor_week' | translate}} {{monitor.weekData[0].current.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].current.aantal_dieren)">{{monitor.weekData[0].current.aantal_dieren | abs | customNumber}} <span class="passive">{{'unit_pieces' | translate}}</span></div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].current.aantal_dieren)">-</div>
                            </div>
                        </div>
                    </div>
                    <div class="previous">
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previous.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous.diff_aantal_dieren)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous.diff_aantal_dieren)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous.diff_aantal_dieren)}}"></i> <span>{{monitor.weekData[0].previous.diff_aantal_dieren | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous.diff_aantal_dieren)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previous4.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous4.diff_aantal_dieren)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous4.diff_aantal_dieren)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous4.diff_aantal_dieren)}}"></i> <span>{{monitor.weekData[0].previous4.diff_aantal_dieren | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous4.diff_aantal_dieren)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previousYear.weekNr}} {{monitor.weekData[0].previousYear.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previousYear.diff_aantal_dieren)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previousYear.diff_aantal_dieren)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previousYear.diff_aantal_dieren)}}"></i> <span>{{monitor.weekData[0].previousYear.diff_aantal_dieren | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previousYear.diff_aantal_dieren)">-</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- totaal aantal slachtingen niet belgisch -->
                <div class="index-panel">
                    <div class="title">{{'monitor_descr_aantal_dieren_niet_be' | translate}}</div>
                    <div class="current">
                        <div class="wrap">
                            <div class="label">{{'monitor_week' | translate}} {{monitor.weekData[0].current.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].current.aantal_dieren_niet_be)">{{monitor.weekData[0].current.aantal_dieren_niet_be | abs | customNumber}} <span class="passive">{{'unit_pieces' | translate}}</span></div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].current.aantal_dieren_niet_be)">-</div>
                            </div>
                        </div>
                    </div>
                    <div class="previous">
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previous.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous.diff_aantal_dieren_niet_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous.diff_aantal_dieren_niet_be)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous.diff_aantal_dieren_niet_be)}}"></i> <span>{{monitor.weekData[0].previous.diff_aantal_dieren_niet_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous.diff_aantal_dieren_niet_be)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previous4.weekNr}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previous4.diff_aantal_dieren_niet_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previous4.diff_aantal_dieren_niet_be)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previous4.diff_aantal_dieren_niet_be)}}"></i> <span>{{monitor.weekData[0].previous4.diff_aantal_dieren_niet_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previous4.diff_aantal_dieren_niet_be)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov_week' | translate}} {{monitor.weekData[0].previousYear.weekNr}} {{monitor.weekData[0].previousYear.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be)}}"></i> <span>{{monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.weekData[0].previousYear.diff_aantal_dieren_niet_be)">-</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- monthly -->
            <h2>{{'monitor_monthly_statistics' | translate}}</h2>
            <div class="index-panels">
                <!-- aantal zeugen -->
                <div class="index-panel">
                    <div class="title">{{'monitor_descr_aantal_zeugen' | translate}}</div>
                    <div class="current">
                        <div class="wrap">
                            <div class="label">{{(monitor.monthData[0].current.year+'-'+(monitor.monthData[0].current.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM' | titlecase}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.monthData[0].current.aantal_zeugen)">{{monitor.monthData[0].current.aantal_zeugen | abs | customNumber}} <span class="passive">{{'unit_pieces' | translate}}</span></div>
                            </div>
                            <div *ngIf="!helpersService.isDefined(monitor.monthData[0].current.aantal_zeugen)">-</div>
                        </div>
                    </div>
                    <div class="previous">
                        <div class="wrap">
                            <div class="label">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previous.year+'-'+(monitor.monthData[0].previous.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previous.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.monthData[0].previous.diff_aantal_zeugen)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previous.diff_aantal_zeugen)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previous.diff_aantal_zeugen)}}"></i> <span>{{monitor.monthData[0].previous.diff_aantal_zeugen | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previous.diff_aantal_zeugen)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previous6.year+'-'+(monitor.monthData[0].previous6.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previous6.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.monthData[0].previous6.diff_aantal_zeugen)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previous6.diff_aantal_zeugen)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previous6.diff_aantal_zeugen)}}"></i> <span>{{monitor.monthData[0].previous6.diff_aantal_zeugen | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previous6.diff_aantal_zeugen)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previousYear.year+'-'+(monitor.monthData[0].previousYear.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previousYear.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.monthData[0].previousYear.diff_aantal_zeugen)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previousYear.diff_aantal_zeugen)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previousYear.diff_aantal_zeugen)}}"></i> <span>{{monitor.monthData[0].previousYear.diff_aantal_zeugen | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previousYear.diff_aantal_zeugen)">-</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- aantal zeugen be -->
                <div class="index-panel">
                    <div class="title">{{'monitor_descr_aantal_zeugen_be' | translate}}</div>
                    <div class="current">
                        <div class="wrap">
                            <div class="label">{{(monitor.monthData[0].current.year+'-'+(monitor.monthData[0].current.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM' | titlecase}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.monthData[0].current.aantal_zeugen_be)">{{monitor.monthData[0].current.aantal_zeugen_be | abs | customNumber}} <span class="passive">{{'unit_pieces' | translate}}</span></div>
                            </div>
                            <div *ngIf="!helpersService.isDefined(monitor.monthData[0].current.aantal_zeugen_be)">-</div>
                        </div>
                    </div>
                    <div class="previous">
                        <div class="wrap">
                            <div class="label">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previous.year+'-'+(monitor.monthData[0].previous.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previous.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.monthData[0].previous.diff_aantal_zeugen_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previous.diff_aantal_zeugen_be)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previous.diff_aantal_zeugen_be)}}"></i> <span>{{monitor.monthData[0].previous.diff_aantal_zeugen_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previous.diff_aantal_zeugen_be)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previous6.year+'-'+(monitor.monthData[0].previous6.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previous6.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.monthData[0].previous6.diff_aantal_zeugen_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previous6.diff_aantal_zeugen_be)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previous6.diff_aantal_zeugen_be)}}"></i> <span>{{monitor.monthData[0].previous6.diff_aantal_zeugen_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previous6.diff_aantal_zeugen_be)">-</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <div class="label">{{'monitor_tov' | translate}} {{(monitor.monthData[0].previousYear.year+'-'+(monitor.monthData[0].previousYear.month | leadingZeros : 2)+'-01') | formatTs : 'MMMM'}} {{monitor.monthData[0].previousYear.year}}</div>
                            <div class="value">
                                <div *ngIf="helpersService.isDefined(monitor.monthData[0].previousYear.diff_aantal_zeugen_be)" class="ticker d-inline-flex align-items-center" [ngClass]="indexService.getDirectionClass(monitor.monthData[0].previousYear.diff_aantal_zeugen_be)">
                                    <i class="im-icon im-icon-{{indexService.getDirectionIcon(monitor.monthData[0].previousYear.diff_aantal_zeugen_be)}}"></i> <span>{{monitor.monthData[0].previousYear.diff_aantal_zeugen_be | abs | customNumber}}</span> <span style="margin-left: 5px;">{{'unit_pieces' | translate}}</span>
                                </div>
                                <div *ngIf="!helpersService.isDefined(monitor.monthData[0].previousYear.diff_aantal_zeugen_be)">-</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="asterix">{{'monitor_asterix' | translate}}</div>

    </ng-container>
</div>