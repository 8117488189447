<div class="container page users">

    <!-- title row -->
    <div class="row">
        <div class="col-sm-6"><h1>{{'users_title' | translate}}</h1></div>
        <div class="col-sm-6">
            <div class="d-flex flex-column align-items-end button-wrap">
                <button class="button green text-center justify-content-center" (click)="addUser()"><i class="im-icon im-icon-plus"></i><span>{{'users_add_user' | translate}}</span></button>
            </div>
        </div>
    </div>

    <div class="panel position-relative">

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">
                
            <div class="table-scroll">
                <app-table
                    [heads]="tableHeads" 
                    [data]="users" 
                    [sort]=""
                    [RPP]=""
                    [startRow]=""
                    [totalRows]=""
                    [loading]="false"
                    emptyState=""
                    (sortChange)="false"
                    (clickAction)="actionClick($event.item, $event.action)"
                    (startRowChange)="false"
                ></app-table>
            </div>

        </ng-container>

    </div>

</div>