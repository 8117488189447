<div class="container page profile">

    <!-- title -->
    <div class="d-flex justify-content-between align-items-end">
        <div><h1>{{'profile_title' | translate}}</h1></div>
    </div>

    <div class="panel position-relative">

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">
                
            <app-register-form
                [FORM]="formSTR"
                [READONLYFORM]="formSTRCopy"
                [validation]="validation"
                (saveSection)="saveSection($event)"
                [activeSection]="activeSection"
                (editSection)="editSection($event);"
                formType="profile"
                detail="profile"
                [saving]="saving"
            ></app-register-form>

        </ng-container>

    </div>

</div>