import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HelpersService } from '../../../services/helpers.service';
import { ResourceService } from '../../../services/resource.service';
import { SearchService } from '../../../services/search.service';
import { DefaultService } from '../../../utils/api';
import { formatTsPipe, TranslatePipe } from '../../../utils/pipes';

@Component({
    selector: 'app-admin-subscriptions',
    templateUrl: './admin-subscriptions.component.html',
    styleUrls: ['./admin-subscriptions.component.scss']
})
export class AdminSubscriptionsComponent implements OnInit {
    items: any[] = null;

    ready: boolean = false;

    RPP: number = 20;
    startRow: number = 0;
    totalRows: number = null;
    tableLoading: boolean = true;
    SORT: any = {};
    SEARCH: any = {};
    exportBusy: boolean = false;

    previousParams: any = null;

    TranslatePipe = inject(TranslatePipe);

    tableHeads: any = [
        {
            width: '25%',
            name: this.TranslatePipe.transform('adminsubscriptions_th_email'),
            code: 'email',
            sortable: false
        },
        {
            width: '25%',
            name: this.TranslatePipe.transform('adminsubscriptions_th_language'),
            code: 'language',
            sortable: false
        },
        {
            width: '25%',
            name: this.TranslatePipe.transform('adminsubscriptions_th_species'),
            code: 'speciesDisplay',
            sortable: false
        },
        {
            width: '25%',
            name: this.TranslatePipe.transform('adminsubscriptions_th_confirmedTS'),
            code: 'confirmedTS',
            sortable: true
        }
    ];

    constructor(
        public formatTsPipe: formatTsPipe,
        private DefaultService: DefaultService,
        private Router: Router,
        public HelpersService: HelpersService,
        private SearchService: SearchService,
        private Route: ActivatedRoute,
        private toastr: ToastrService,
        private ResourceService: ResourceService
    ) {
        this.Route.queryParams.subscribe((params: any) => {
            const parameters = Object.assign({}, params);
            if (parameters.id) delete parameters['id'];
            if (JSON.stringify(parameters) === JSON.stringify(this.previousParams)) {
                return;
            }
            this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
            this.SEARCH = parameters.search
                ? this.getInitialSearch(JSON.parse(parameters.search))
                : this.getInitialSearch();
            this.SORT = parameters.sort ? JSON.parse(parameters.sort) : this.getInitialSort();
            let code = undefined;
            if (parameters.page !== this.previousParams?.page || this.previousParams?.page !== undefined)
                code = 'changeStartRow';
            this.getItems(code);
            this.previousParams = parameters;
        });
    }

    ngOnInit(): void {}

    getInitialSearch(SEARCH?: any) {
        if (SEARCH) {
            return {
                ...this.getDefaultSearch(),
                ...SEARCH
            };
        } else return this.getDefaultSearch();
    }

    getDefaultSearch() {
        return {};
    }

    getInitialSort() {
        return {
            code: 'confirmedTS',
            dir: 'desc'
        };
    }

    clickSort(code: string, dir?: string) {
        this.Router.navigate([], {
            queryParams: {
                sort: JSON.stringify(
                    this.SearchService.getCleanSort(
                        this.SearchService.getSort(code, this.SORT, dir),
                        this.getInitialSort()
                    )
                )
            },
            queryParamsHandling: 'merge'
        });
    }

    getItems(code?: string) {
        // code: changestartrow
        const observables$: Observable<any>[] = [of(null)];

        const SORT = { ...this.SORT };
        const SEARCH = this.SearchService.getSearchBody(this.SEARCH, SORT, this.RPP, this.startRow);
        this.tableLoading = true;
        const items$ = this.DefaultService.subscriptionsGetAll(
            SEARCH.startRow,
            SEARCH.RPP,
            SEARCH.orderBy,
            SEARCH.term
        ).pipe(
            tap((next: any) => {
                this.items = this.getFormattedItems(next.data);
                this.totalRows = next.rows;
                this.tableLoading = false;
                if (code === 'changeStartRow') {
                    document.getElementById('items').scrollIntoView();
                }
            })
        );
        observables$.push(items$);

        forkJoin(observables$).subscribe(() => {
            this.ready = true;
        });
    }

    search = (reset?: boolean, type?: string) => {
        if (reset) {
            this.Router.navigate([], {
                queryParams: {
                    search: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.Router.navigate([], {
                queryParams: {
                    search: JSON.stringify(this.SearchService.getCleanSearch(this.SEARCH)),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
    };

    getFormattedItems(items) {
        return items.map((item) => {
            return this.getFormattedItem(item);
        });
    }

    getFormattedItem(item) {
        if (item.confirmedTS) {
            item.confirmedTS = `${this.formatTsPipe.transform(
                item.confirmedTS,
                'D MMM YYYY'
            )} ${this.TranslatePipe.transform('misc_date_at')} ${this.formatTsPipe.transform(
                item.confirmedTS,
                'HH:mm'
            )}`;
        } else item.confirmedTS = '-';
        item = {
            ...item,
            email: { type: 'default', value: item.email },
            language: { type: 'default', value: this.ResourceService.getLanguageByCode(item.language).title },
            confirmedTS: { type: 'default', value: item.confirmedTS },
            speciesDisplay: item.species
                .map((x: string) => this.TranslatePipe.transform(`newsletter_for_${x}`))
                .join(', ')
        };
        return item;
    }

    resetStartRow() {
        this.startRow = 0;
        this.getItems();
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.getItems('changeStartRow');
    }

    export() {
        this.exportBusy = true;
        // this.DefaultService.subscriptionsExportAll().subscribe((data) => {
        setTimeout(() => {
            this.exportBusy = false;
        }, 500);
        // });
        window.location.href = '/api/subscriptions/export';
    }
}
