<div class="container page admin-companies">

    <!-- title row -->
    <div class="row">
        <div class="col-12"><h1>{{'admincompanies_title' | translate}}</h1></div>
    </div>

    <div class="panel position-relative" id="items-to-accept" *ngIf="ready && itemsToAccept?.length && user.company.type !== 'gov'">

        <h2>{{'admincompanies_title_to_accept' | translate}} <span [class.saving]="tableLoadingToAccept" class="saving--pre" *ngIf="ready">({{totalRowsToAccept}})</span></h2>

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">
            <!-- goed te keuren -->
            <div class="table-scroll-- table-wrap">
                <app-table
                    [heads]="tableHeadsToAccept" 
                    [data]="itemsToAccept" 
                    [RPP]="RPPToAccept"
                    [startRow]="startRowToAccept"
                    [totalRows]="totalRowsToAccept"
                    [loading]="tableLoadingToAccept"
                    [emptyState]="'admincompanies_emptystate' | translate"
                    (sortChange)="false"
                    (clickAction)="actionClick($event.item, $event.action)"
                    (clickData)="tableClick($event.item, $event.head)"
                    (startRowChange)="changeStartRowToAccept($event)"
                ></app-table>
            </div>
        </ng-container>

    </div>

    <div class="panel position-relative" id="items">

        <h2>{{'admincompanies_title_accepted' | translate}} <span [class.saving]="tableLoading" class="saving--pre" *ngIf="ready">({{totalRows}})</span></h2>

        <div class="search-bar-wrap">
            <div class="d-inline-flex search-bar">
                <input 
                    class="flex-grow-1"
                    [(ngModel)]="SEARCH.term" 
                    (keyup.enter)="!tableLoading &&search()"
                    placeholder="{{'admincompanies_search_placeholder' | translate}}"
                />
                <app-field-select 
                    [model]="SEARCH.activity" 
                    [options]="options.activity"
                    (modelChange)="SEARCH.activity = $event" 
                    [placeholder]="'admincompanies_search_activity_placeholder' | translate">
                </app-field-select>
                <app-field-select 
                    [model]="SEARCH.species" 
                    [options]="options.species"
                    (modelChange)="SEARCH.species = $event" 
                    [placeholder]="'admincompanies_search_species_placeholder' | translate">
                </app-field-select>
                <app-field-select 
                    [model]="SEARCH.status" 
                    [options]="options.status"
                    (modelChange)="SEARCH.status = $event" 
                    [placeholder]="'admincompanies_search_status_placeholder' | translate">
                </app-field-select>
                <button class="button text-center justify-content-center" (click)="search()" [class.disabled]="tableLoading"><i class="im-icon im-icon-search"></i></button>
            </div>
            <button *ngIf="HelpersService.getParam('search')" (click)="search(true)" class="button-link reset-search">{{'misc_reset_search' | translate}}</button>
        </div>

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">
            <!-- goedgekeurd -->
            <div class="table-scroll-- table-wrap">
                <app-table
                    [heads]="tableHeads" 
                    [data]="items" 
                    [sort]="SORT"
                    [RPP]="RPP"
                    [startRow]="startRow"
                    [totalRows]="totalRows"
                    [loading]="tableLoading"
                    (clickData)="tableClick($event.item, $event.head)"
                    [emptyState]="'admincompanies_emptystate' | translate"
                    (sortChange)="clickSort($event.code, $event.dir)"
                    (clickAction)="actionClick($event.item, $event.action)"
                    (startRowChange)="changeStartRow($event)"
                ></app-table>
            </div>
        </ng-container>

    </div>

</div>