import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './pages/profile/profile.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { NewIndexComponent } from './pages/new-index/new-index.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { RegisterActivitiesComponent } from './pages/register-activities/register-activities.component';
import { RegisterComponent } from './pages/register/register.component';
import { SubscriptionConfirmedComponent } from './pages/subscription-confirmed/subscription-confirmed.component';
import { LeaveGuardService } from './services/leave-guard.service';
import { RouteGuardService } from './services/route-guard.service';
import { PartsComponent } from './pages/parts/parts.component';
import { UsersComponent } from './pages/users/users.component';
import { FirstloginComponent } from './pages/firstlogin/firstlogin.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SubscriptionCancelledComponent } from './pages/subscription-cancelled/subscription-cancelled.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { TermsComponent } from './pages/terms/terms.component';
import { RegistrationHistoryComponent } from './pages/registration-history/registration-history.component';
import { RegistrationHistoryDetailComponent } from './pages/registration-history-detail/registration-history-detail.component';
import { PickCompanyComponent } from './pages/pick-company/pick-company.component';
import { AdminUsersComponent } from './pages/admin/admin-users/admin-users.component';
import { AdminCompaniesComponent } from './pages/admin/admin-companies/admin-companies.component';
import { AdminCompanyComponent } from './pages/admin/admin-company/admin-company.component';
import { AdminSubscriptionsComponent } from './pages/admin/admin-subscriptions/admin-subscriptions.component';
import { SlaughterhouseMonitorComponent } from './pages/slaughterhouse-monitor/slaughterhouse-monitor.component';

let browserLang = null;
if (navigator) {
    browserLang = navigator.language || navigator['userLanguage'];
}
if (browserLang && browserLang.indexOf('fr') != -1) {
    browserLang = 'fr';
} else browserLang = null;
const localstorageLang = localStorage.getItem('publicLanguage');

export const routes: Routes = [
    // about public
    {
        path: ':lang/about',
        component: AboutComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true }
    },
    // privacy policy public
    {
        path: ':lang/privacy-policy',
        component: PrivacyPolicyComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true }
    },
    // cookies public
    {
        path: ':lang/cookies',
        component: CookiesComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true }
    },
    // terms public
    {
        path: ':lang/terms',
        component: TermsComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true }
    },
    // contact public
    {
        path: ':lang/contact',
        component: ContactComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true, showCaptcha: true }
    },
    // register public
    {
        path: ':lang/register',
        component: RegisterComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true }
    },
    // register activities
    {
        path: ':lang/register-activities',
        component: RegisterActivitiesComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // register firstlogin
    {
        path: ':lang/firstlogin/:id/:hash',
        component: FirstloginComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true, logout: true }
    },
    // forgot password
    {
        path: ':lang/reset-password/:id/:hash',
        component: ForgotPasswordComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true, logout: true }
    },
    // new index
    {
        path: ':lang/new',
        component: NewIndexComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // profile
    {
        path: ':lang/profile',
        component: ProfileComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // registration history
    {
        path: ':lang/registration-history',
        component: RegistrationHistoryComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // registration history
    {
        path: ':lang/registration-history/:year/:week',
        component: RegistrationHistoryDetailComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // parts
    {
        path: ':lang/parts',
        component: PartsComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // users
    {
        path: ':lang/users',
        component: UsersComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // subscription confirmed
    {
        path: ':lang/subscription-confirmed',
        component: SubscriptionConfirmedComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true }
    },
    // cancel subscription
    {
        path: ':lang/cancel-subscription/:id/:hash',
        component: SubscriptionCancelledComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true }
    },
    // pick company
    {
        path: ':lang/select-company',
        component: PickCompanyComponent,
        data: { isPublic: false }
    },

    // home public
    {
        path: ':lang',
        component: HomeComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true, showNewsletter: true, showCaptcha: false }
    },
    // slaughterhouseMonitor public
    {
        path: ':lang/slaughterhouse-monitor',
        redirectTo: ':lang/slaughterhouse-monitor/pork'
    },
    {
        path: ':lang/slaughterhouse-monitor/:indexType',
        component: SlaughterhouseMonitorComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: true, showNewsletter: true, showCaptcha: false, isSlaughterhouseMonitor: true }
    },
    // slaughterhouseMonitor public + indexType
    // {
    //     path: ':lang/:indexType',
    //     component: SlaughterhouseMonitorComponent,
    //     canDeactivate: [LeaveGuardService],
    //     canActivate: [RouteGuardService],
    //     data: { isPublic: true, showNewsletter: true, showCaptcha: false, isSlaughterhouseMonitor: true }
    // },
    // home public + indexType
    {
        path: ':lang/:indexType',
        component: HomeComponent,
        canDeactivate: [LeaveGuardService],
        canActivate: [RouteGuardService],
        data: { isPublic: true, showNewsletter: true, showCaptcha: false }
    },
    // admin companies
    {
        path: ':lang/admin/companies',
        component: AdminCompaniesComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // admin company
    {
        path: ':lang/admin/companies/:id',
        component: AdminCompanyComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // admin users
    {
        path: ':lang/admin/users',
        component: AdminUsersComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },
    // admin subscriptions
    {
        path: ':lang/admin/subscriptions',
        component: AdminSubscriptionsComponent,
        canDeactivate: [LeaveGuardService],
        data: { isPublic: false }
    },

    // 404
    { path: '**', redirectTo: `/${localstorageLang || browserLang || 'nl'}` }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
