<!-- <div class="newsletter" *ngIf="showNewsletter"> -->
<div class="newsletter position-relative" [class.success]="successState" *ngIf="showNewsletter && !user">
    <div class="newsletter-wrap newsletter-input-wrap container">
        <div class="left">
            <div class="d-flex">
                <div class="d-flex flex-column">
                    <div class="title">{{'newsletter_title' | translate}}</div>
                    <div class="subtitle">{{'newsletter_subtitle' | translate}}</div>
                </div>
            </div>
        </div>
        <div class="right newsletter-input-col">
            <div class="d-flex flex-column w-100">
                <div class="d-flex w-100 newsletter-input-col-inner">
                    <input 
                        class="flex-grow-1"
                        [(ngModel)]="newsletterSTR.email" 
                        (keyup.enter)="submitNewsletter()"
                        placeholder="{{'newsletter_placeholder' | translate}}"
                        data-1p-ignore
                    />
                    <label *ngIf="speciesPartCodes?.length > 1">
                        <span>{{'newsletter_for' | translate}}</span>
                        <select [ngModel]="newsletterSTR.species" (ngModelChange)="newsletterSTR.species = $event">
                            <option *ngFor="let option of newsletterSpeciesOptions;" [ngValue]="option.value">{{option.title}}</option>
                        </select>
                    </label>
                    <button class="button newsletter-btn text-center button--spacious justify-content-center" (click)="submitNewsletter()" [class.disabled]="saving"><span>{{'newsletter_subscribe' | translate}}</span><i class="im-icon im-icon-arrow-right"></i></button>
                </div>
                <span *ngIf="validation.email" class="error-field-display--lone">{{validation.email}}</span>
                <span *ngIf="validation.captcha" class="error-field-display--lone">{{validation.captcha | translate}}</span>
            </div>
        </div>
    </div>
    <!-- success -->
    <div class="success-state opacity-hide" [class.opacity-show]="successState">

        <div class="newsletter-wrap container" style="height: 100%;">
            <div class="row" style="height: 100%;">
                <div class="col-md-12" style="height: 100%;">
                    <div class="success-msg d-flex w-100 align-items-center justify-content-left" style="height: 100%;">
                        <img src="/assets/img/icons/round-check.svg" alt="" class="check">
                        <div class="d-flex flex-column">
                            <span class="title">{{'newsletter_success_title' | translate}}</span>
                            <span class="subtitle">{{'newsletter_success_subtitle' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="footer">
    <div class="top">
        <div class="logos">
            <div class="logo-wrap d-flex flex-column align-items-center">
                <div class="subtitle">{{'footer_federation_subtitle' | translate}}</div>
                <img class="febev" src="/assets/img/febev.png" alt="Febev vzw">
                <span>{{'footer_febev_vzw' | translate}}</span>
            </div>
            @if (weekInfo.porkBlackBox !== false) {
                <div class="sep-v"></div>
                <!-- fenavian -->
                <div class="logo-wrap d-flex flex-column align-items-center" *ngIf="!isSlaughterhouseMonitor">
                    <div class="subtitle">{{'footer_federation_subtitle_collaborate' | translate}}</div>
                    <img class="fenavian" src="/assets/img/fenavian.png" alt="Fenavian vzw">
                    <span>{{'footer_fenavian_vzw' | translate}}</span>
                </div>
            }
            <!-- ivb -->
            <div class="logo-wrap d-flex flex-column align-items-center" *ngIf="isSlaughterhouseMonitor">
                <div class="subtitle">{{'footer_federation_subtitle_collaborate' | translate}}</div>
                <img class="ivb" src="/assets/img/ivb.png" alt="I.V.B. vzw">
                <span>{{'footer_ivb' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="bot">
        &copy; {{getYear()}} - Meatindex - <a (click)="HelpersService.toTop()" [routerLink]="[HelpersService.getUrl('privacy-policy')]">{{'footer_privacy' | translate}}</a> - <a (click)="HelpersService.toTop()" [routerLink]="[HelpersService.getUrl('cookies')]">{{'footer_cookies' | translate}}</a>
    </div>
</footer>