<div class="field field-text" [ngClass]="">
    <span *ngIf="label" class="label">{{label}}</span>
    <div class="d-flex align-items-center w-100 position-relative">
        <input 
            [class.error-field]="error"
            [type]="getType()"
            type="text"
            class="flex-grow-1"
            autocomplete="new-password"
            (focus)="this.disabled = false"
            style="max-width: 360px;"
            (ngModelChange)="emit(null)"
            [(ngModel)]="localModel" 
            (keyup.enter)="emit(null); enter.emit()"
            [placeholder]="placeholder"
        />
        <button *ngIf="localModel && localModel.length" (click)="togglePwd = !togglePwd" tabindex="-1" class="toggle-show button-link"><ng-container *ngIf="!togglePwd">{{'fieldtext_show_pwd' | translate}}</ng-container><ng-container *ngIf="togglePwd">{{'fieldtext_hide_pwd' | translate}}</ng-container></button>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
    <div class="conditions d-flex" *ngIf="!hideCheck">
        <div class="condition" [class.active]="valid('lower')">
            <div class="top">a</div>
            <div class="bot">{{'fieldpassword_condition_lowercase' | translate}}</div>
        </div>
        <div class="condition" [class.active]="valid('upper')">
            <div class="top">A</div>
            <div class="bot">{{'fieldpassword_condition_uppercase' | translate}}</div>
        </div>
        <div class="condition" [class.active]="valid('number')">
            <div class="top">9</div>
            <div class="bot">{{'fieldpassword_condition_number' | translate}}</div>
        </div>
        <div class="condition" [class.active]="valid('special')">
            <div class="top">#</div>
            <div class="bot">{{'fieldpassword_condition_special' | translate}}</div>
        </div>
        <div class="condition" [class.active]="valid('amount')">
            <div class="top">8+</div>
            <div class="bot">{{'fieldpassword_condition_amount' | translate}}</div>
        </div>
    </div>
</div>