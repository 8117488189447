<div class="d-flex align-items-center justify-content-center" style="height: 100%;">
    <div class="pick-company">
        <h1>{{'pickcompany_title' | translate}}</h1>
        <ul>
            <li class="d-flex" (click)="pickCompany(company.id)" *ngFor="let company of companies">
                <i class="icon icon-company"></i>
                <span class="flex-grow-1">{{company.company}}</span>
                <i class="im-icon im-icon-arrow-right align-self-end"></i>
            </li>
        </ul>
    </div>
</div>