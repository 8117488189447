import { Component, OnInit, inject } from '@angular/core';
import { TranslatePipe } from '../../utils/pipes';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    TranslatePipe = inject(TranslatePipe);
    content: string = this.TranslatePipe.transform('about_content');

    constructor(public Route: ActivatedRoute) {}

    ngOnInit(): void {}
}
