import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ResourceService } from '../../services/resource.service';
import { HelpersService } from '../../services/helpers.service';
import { IndexService } from '../../services/index.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DefaultService } from '../../utils/api/api/default.service';

@Component({
    selector: 'app-registration-history-detail',
    templateUrl: './registration-history-detail.component.html',
    styleUrls: ['./registration-history-detail.component.scss']
})
export class RegistrationHistoryDetailComponent implements OnInit {
    history: any = {};
    validation: any = {};
    weekInfo: any = {};
    inputWeekInfo: any = {};
    ready: boolean = false;
    changes: boolean = false;
    saving: boolean = false;
    editName: string = '';
    editTS: string = '';

    year: string = '';
    weekNr: string = '';

    @Input() govYear: number;
    @Input() govWeek: number;
    @Input() govCompanyId: string;

    @Output() dismiss = new EventEmitter();

    constructor(
        private ResourceService: ResourceService,
        private ModalService: NgbModal,
        private Router: Router,
        public IndexService: IndexService,
        public HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService,
        private Route: ActivatedRoute,
        private DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        this.year = this.Route.snapshot.params['year'];
        this.weekNr = this.Route.snapshot.params['week'];

        if (!this.govCompanyId) {
            // get index
            this.IndexService.getDataInput(this.year, this.weekNr).subscribe((next: any) => {
                this.history = next.species.map((species) => {
                    if (!species.parts) {
                        species.parts = [];
                    }
                    return {
                        ...species,
                        parts: species.parts.map((part) => {
                            const data = part.data || {};
                            data.fluctuation = data.fluctuation || 0;
                            data.amount = data.amount || 0;
                            if (data.price) {
                                data.price = data.price / 100;
                            }
                            if (data.prevPrice) {
                                data.prevPrice = data.prevPrice / 100;
                            }
                            return { ...part, data: data };
                        })
                    };
                });
                this.inputWeekInfo = next.weekInfo;
                this.editName = next.editName;
                this.editTS = next.editTS;
                this.ready = true;
            });
        } else {
            // get index GOV
            this.DefaultService.dataInputGetDataInputForWeek(this.govYear, this.govWeek, this.govCompanyId).subscribe(
                (next: any) => {
                    this.history = next.species.map((species) => {
                        if (!species.parts) {
                            species.parts = [];
                        }
                        return {
                            ...species,
                            parts: species.parts.map((part) => {
                                const data = part.data || {};
                                data.fluctuation = data.fluctuation || 0;
                                data.amount = data.amount || 0;
                                if (data.price) {
                                    data.price = data.price / 100;
                                }
                                if (data.prevPrice) {
                                    data.prevPrice = data.prevPrice / 100;
                                }
                                return { ...part, data: data };
                            })
                        };
                    });
                    this.inputWeekInfo = next.weekInfo;
                    this.editName = next.editName;
                    this.editTS = next.editTS;
                    this.ready = true;
                }
            );
        }
    }
}
