<header class="d-flex" [class.is-admin]="isAdmin || user?.company?.type === 'gov'">
    <div class="container container--mx d-flex flex-row align-items-center justify-content-between">
        <nav class="d-flex h-100 left" *ngIf="ready">
            <ul class="d-flex flex-row m-0">
                <li>
                    <a (click)="logoClick($event)" [routerLink]="" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="logo flex-shrink-0"><img style="transform: scale(1) translate(0px,0px); cursor: pointer;" [src]="(isAdmin || user?.company?.type === 'gov') ? '/assets/img/misc/logo-admin.svg' : '/assets/img/misc/logo.svg'" alt="{{appName}}"></a>
                </li>
                <!-- public -->
                <ng-container *ngIf="!AuthenticationService.isFederation() && !hideNav && user?.company?.type !== 'gov'">
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('', 'home')]"routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_home' | translate}}</a>
                    </li>
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('slaughterhouse-monitor')]"routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_slaughterhousemonitor' | translate}}</a>
                    </li>
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('about')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'nav_about' | translate}}</a>
                    </li>
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('contact')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'nav_contact' | translate}}</a>
                    </li>
                </ng-container>
                <!-- federation -->
                <ng-container *ngIf="AuthenticationService.isFederation() && !hideNav && !isAdmin && user?.company?.type !== 'gov'">
                    <li *ngFor="let species of speciesPartCodes">
                        <a (click)="selectSpecies(species)" [routerLink]="[HelpersService.getUrl('' + species)]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'nav_' + species | translate}}</a>
                    </li>
                </ng-container>
                <!-- admin -->
                <ng-container *ngIf="isAdmin && !hideNav">
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('', 'home')]"routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_home' | translate}}</a>
                    </li>
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('slaughterhouse-monitor')]"routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_slaughterhousemonitor' | translate}}</a>
                    </li>
                    <li>
                        <a [routerLink]="[this.HelpersService.getUrl('admin/companies')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_companies' | translate}} <span *ngIf="adminCompaniesToAccept" class="count">{{adminCompaniesToAccept}}</span></a>
                    </li>
                    <li>
                        <a [routerLink]="[this.HelpersService.getUrl('admin/users')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_users' | translate}}</a>
                    </li>
                    <li>
                        <a [routerLink]="[this.HelpersService.getUrl('admin/subscriptions')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_subscriptions' | translate}}</a>
                    </li>
                </ng-container>
                <!-- gov -->
                <ng-container *ngIf="user?.company?.type === 'gov'">
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('', 'home')]"routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_home' | translate}}</a>
                    </li>
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('slaughterhouse-monitor')]"routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_slaughterhousemonitor' | translate}}</a>
                    </li>
                    <li>
                        <a [routerLink]="[HelpersService.getUrl('admin/companies')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_companies' | translate}}</a>
                    </li>
                </ng-container>
            </ul>
        </nav>
        <div class="d-flex h-100 right align-items-center">
            <!-- public -->
            <ng-container *ngIf="!user">
                <div class="language-switch d-inline-flex align-items-center h-100">
                    <app-field-select 
                        id="language-switch"
                        [model]="publicLanguage" 
                        [options]="languageOptions"
                        (modelChange)="setLanguage($event)" 
                        arrowFit="/assets/img/icons/icon-dropdown-darker.svg"
                        label=""
                        [error]=""
                        class="d-flex h-100"
                        placeholder="">
                    </app-field-select>
                </div>
                <div class="sep"></div>
                <div>
                    <button class="button green--light" (click)="openLoginModal()" [class.disabled]="false">{{'nav_login' | translate}}</button>
                </div>
            </ng-container>
            <!-- private -->
            <ng-container *ngIf="user">
                <div style="cursor: pointer;" class="p-0 ml-2 position-relative d-flex align-items-center" (click)="profileOpen = true;" appClickOutside (clickOutside)="profileOpen = null;">
                    <app-avatar (click)="!hideNav && toggleProfileOpen(); $event.stopPropagation();" [class.hightlight-hover]="!profileOpen" style="transition: .2s ease;" [user]="user" [subtitle]="(user.company && user.company.company) || ''" [title]="(user.firstname || '') + ' ' + (user.lastname || '')" [subtitle]="" [size]="40" [fontSize]="14"></app-avatar>
                    <app-popover [open]="profileOpen" positionClass="left" style="transform: translatey(30px);">
                        <div class="profile-popover d-flex flex-column">
                            <!-- <div class="d-flex p-s head">
                                <app-avatar [user]="user" [title]="(user.firstname || '') + ' ' + (user.lastname || '')" [subtitle]="" size="40" fontSize="14"></app-avatar>
                            </div> -->
                            <div class="options">
                                <ul>
                                    <li class="d-flex">
                                        <a class="d-flex align-items-center" (click)="setProfileOpen(false)" [routerLink]="[HelpersService.getUrl('profile')]">
                                            <span><i class="im-icon im-icon-profile"></i></span>
                                            <div class="flex-grow-1">{{'header_profile' | translate}}</div>
                                        </a>
                                    </li>
                                    <li class="d-flex" *ngIf="AuthenticationService.userHasRole('main')">
                                        <a class="d-flex align-items-center" (click)="setProfileOpen(false)" [routerLink]="[HelpersService.getUrl('users')]">
                                            <span><i class="im-icon im-icon-profile"></i></span>
                                            <div class="flex-grow-1">{{'header_users' | translate}}</div>
                                        </a>
                                    </li>
                                    <li class="d-flex" *ngIf="!AuthenticationService.isFederation() && user?.company?.type !== 'gov'">
                                        <a class="d-flex align-items-center" (click)="setProfileOpen(false)" [routerLink]="[HelpersService.getUrl('registration-history')]">
                                            <span><i class="im-icon im-icon-history"></i></span>
                                            <div class="flex-grow-1">{{'header_registration_history' | translate}}</div>
                                        </a>
                                    </li>
                                    <li class="d-flex" *ngIf="!AuthenticationService.isFederation() && user?.company?.type !== 'gov'">
                                        <a class="d-flex align-items-center" (click)="setProfileOpen(false)" [routerLink]="[HelpersService.getUrl('parts')]">
                                            <span><i class="im-icon im-icon-settings-outline"></i></span>
                                            <div class="flex-grow-1">{{'header_parts' | translate}}</div>
                                        </a>
                                    </li>
                                    <li class="d-flex" *ngIf="user.companies && user.companies.length > 1">
                                        <a class="d-flex align-items-center" [attr.href]="HelpersService.getUrl('select-company')">
                                            <span><i class="icon icon-company"></i></span>
                                            <div class="flex-grow-1">{{'header_pick_company' | translate}}</div>
                                        </a>
                                    </li>
                                    <li class="d-flex logout" (click)="AuthenticationService.logout()">
                                        <a class="d-flex align-items-center">
                                            <span><i class="im-icon im-icon-logout" style="transform: translateX(1px);"></i></span>
                                            <div class="flex-grow-1">{{'header_logout' | translate}}</div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </app-popover>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="container container--md justify-content-between" [class.position-fixed]="mobileMenuOpen">
        <div class="d-flex h-100 left">
            <a [routerLink]="" (click)="logoClick($event); mobileMenuOpen = false" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="logo flex-shrink-0 d-flex align-items-center"><img src="/assets/img/misc/logo.svg" alt="{{appName}}"></a>
        </div>
        <div class="d-flex h-100 right align-items-center" *ngIf="!hideNav">
            <button *ngIf="!mobileMenuOpen" (click)="mobileMenuOpen = true"><i class="im-icon im-icon-bars"></i></button>
            <button *ngIf="mobileMenuOpen" (click)="mobileMenuOpen = false"><i class="im-icon im-icon-x-light"></i></button>
        </div>
    </div>
</header>

<!-- mobile menu -->
<nav class="opacity-hide mobile-menu" [class.opacity-show]="!hideNav && mobileMenuOpen">
    <div class="container d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column flex-grow-1">
            <!-- all -->
            <ul *ngIf="!AuthenticationService.isFederation()">
                <li><a (click)="mobileMenuOpen = false" class="d-flex align-items-center" [routerLink]="[HelpersService.getUrl('', 'home')]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_home' | translate}}</a></li>
                <li><a (click)="mobileMenuOpen = false" class="d-flex align-items-center" [routerLink]="[HelpersService.getUrl('slaughterhouse-monitor')]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'nav_slaughterhousemonitor' | translate}}</a></li>
                <li><a (click)="mobileMenuOpen = false" class="d-flex align-items-center" [routerLink]="[HelpersService.getUrl('about')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'nav_about' | translate}}</a></li>
                <li><a (click)="mobileMenuOpen = false" class="d-flex align-items-center" [routerLink]="[HelpersService.getUrl('contact')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'nav_contact' | translate}}</a></li>
            </ul>
            <!-- public -->
            <ul *ngIf="!user">
                <li><a class="d-flex align-items-center" (click)="openLoginModal(); mobileMenuOpen = false" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'nav_login' | translate}}</a></li>
            </ul>
            <!-- federation -->
            <ul *ngIf="AuthenticationService.isFederation()">
                <li *ngFor="let species of speciesPartCodes"><a class="d-flex align-items-center" (click)="selectSpecies(species); mobileMenuOpen = false" [routerLink]="[HelpersService.getUrl('' + species)]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'nav_' + species | translate}}</a></li>
            </ul>
            <!-- private -->
            <ul *ngIf="user">
                <li><a (click)="mobileMenuOpen = false" class="d-flex align-items-center" [routerLink]="[HelpersService.getUrl('profile')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'header_profile' | translate}}</a></li>
                <li *ngIf="AuthenticationService.userHasRole('main')"><a (click)="mobileMenuOpen = false" class="d-flex align-items-center" [routerLink]="[HelpersService.getUrl('users')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'header_users' | translate}}</a></li>
                <li *ngIf="!AuthenticationService.isFederation()"><a (click)="mobileMenuOpen = false" class="d-flex align-items-center" [routerLink]="[HelpersService.getUrl('registration-history')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'header_registration_history' | translate}}</a></li>
                <li *ngIf="!AuthenticationService.isFederation()"><a (click)="mobileMenuOpen = false" class="d-flex align-items-center" [routerLink]="[HelpersService.getUrl('parts')]" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'header_parts' | translate}}</a></li>
                <li *ngIf="user.companies && user.companies.length > 1"><a class="d-flex align-items-center" [attr.href]="HelpersService.getUrl('select-company')" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">{{'header_pick_company' | translate}}</a></li>
                <li class="logout"><a class="d-flex align-items-center" (click)="AuthenticationService.logout(); mobileMenuOpen = false">{{'header_logout' | translate}}</a></li>
            </ul>
        </div>
    </div>
</nav>