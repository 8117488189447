<div [ngClass]="formType">
    <section style="margin-top: -18px;" [class.active]="activeSection == 'company'" *ngIf="formType != 'firstlogin'">
        <h2 class="mb-s">{{'register_company_credentials_title' | translate}}</h2>

        <!-- company name -->
        <div class="row row-form mw-700-px">
            <div class="col-md-7">
                <ng-container *ngIf="formType != 'register'">
                    <div class="display-label">{{'register_company_name' | translate}}</div>
                    <div class="display-value d-flex flex-row">
                        <span>{{READONLYFORM.company.company | display}}</span>
                    </div>
                </ng-container>
                <app-field-text 
                    *ngIf="formType == 'register'"
                    [model]="FORM.company.company" 
                    autocomplete="off"
                    (modelChange)="FORM.company.company = $event"
                    [error]="validation.company.company"
                    label="{{'register_company_name' | translate}}"
                    placeholder="">
                </app-field-text>
            </div>
        </div>

        @if (user?.company?.type !== 'gov' || detail !== 'profile') {
            <!-- company vat -->
            <div class="row row-form mw-700-px">
                <div class="col-md-6">
                    <ng-container *ngIf="formType != 'register'">
                        <div class="display-label">{{'register_company_vat' | translate}}</div>
                        <div class="display-value d-flex flex-row">
                            <span>{{READONLYFORM.company.vat | vat}}</span>
                        </div>
                    </ng-container>
                    <app-field-text 
                        *ngIf="formType == 'register'"
                        [model]="FORM.company.vat" 
                        (modelChange)="FORM.company.vat = $event"
                        afterString="{{'register_company_vat_after' | translate}}"
                        [error]="validation.company.vat"
                        label="{{'register_company_vat' | translate}}"
                        maskPrefix="BE 0"
                        mask="000.000.000"
                        autocomplete="off"
                        placeholder="">
                    </app-field-text>
                </div>
            </div>

            <!-- company address -->
            <div class="row row-form mw-700-px">
                <div class="col-md-5">
                    <ng-container *ngIf="formType != 'register'">
                        <div class="display-label">{{'register_company_street' | translate}}</div>
                        <div class="display-value d-flex flex-row">
                            <span>{{READONLYFORM.company.street | display}}</span>
                        </div>
                    </ng-container>
                    <app-field-text 
                        *ngIf="formType == 'register'"
                        [model]="FORM.company.street" 
                        (modelChange)="FORM.company.street = $event"
                        [error]="validation.company.street"
                        label="{{'register_company_street' | translate}}"
                        autocomplete="off"
                        placeholder="">
                    </app-field-text>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-4">
                            <ng-container *ngIf="formType != 'register'">
                                <div class="display-label">{{'register_company_street_nr' | translate}}</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{READONLYFORM.company.streetNr | display}}</span>
                                </div>
                            </ng-container>
                            <app-field-text 
                                *ngIf="formType == 'register'"
                                [model]="FORM.company.streetNr" 
                                (modelChange)="FORM.company.streetNr = $event"
                                [error]="validation.company.streetNr"
                                label="{{'register_company_street_nr' | translate}}"
                                autocomplete="off"
                                placeholder="">
                            </app-field-text>
                        </div>
                        <div class="col-4" *ngIf="formType == 'register' || READONLYFORM.company.streetBox">
                            <ng-container *ngIf="formType != 'register'">
                                <div class="display-label">{{'register_company_bus' | translate}}</div>
                                <div class="display-value d-flex flex-row">
                                    <span>{{READONLYFORM.company.streetBox | display}}</span>
                                </div>
                            </ng-container>
                            <app-field-text 
                                *ngIf="formType == 'register'"
                                [model]="FORM.company.streetBox" 
                                (modelChange)="FORM.company.streetBox = $event"
                                optionalString="{{'misc_optional' | translate}}"
                                [error]="validation.company.streetBox"
                                label="{{'register_company_bus' | translate}}"
                                autocomplete="off"
                                placeholder="">
                            </app-field-text>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-form mw-700-px">
                <div class="col-md-3">
                    <ng-container *ngIf="formType != 'register'">
                        <div class="display-label">{{'register_company_zipcode' | translate}}</div>
                        <div class="display-value d-flex flex-row">
                            <span>{{READONLYFORM.company.zipcode | display}}</span>
                        </div>
                    </ng-container>
                    <app-field-text 
                        *ngIf="formType == 'register'"
                        [model]="FORM.company.zipcode" 
                        (modelChange)="FORM.company.zipcode = $event"
                        [error]="validation.company.zipcode"
                        label="{{'register_company_zipcode' | translate}}"
                        autocomplete="no"
                        placeholder="">
                    </app-field-text>
                </div>
                <div class="col-md-5">
                    <ng-container *ngIf="formType != 'register'">
                        <div class="display-label">{{'register_company_city' | translate}}</div>
                        <div class="display-value d-flex flex-row">
                            <span>{{READONLYFORM.company.city | display}}</span>
                        </div>
                    </ng-container>
                    <app-field-text 
                        *ngIf="formType == 'register'"
                        [model]="FORM.company.city" 
                        (modelChange)="FORM.company.city = $event"
                        [error]="validation.company.city"
                        label="{{'register_company_city' | translate}}"
                        autocomplete="off"
                        placeholder="">
                    </app-field-text>
                </div>
            </div>

            <!-- company tel -->
            <div class="row row-form mw-700-px">
                <div class="col-md-4">
                    <ng-container *ngIf="formType != 'register'">
                        <div class="display-label">{{'register_contact_tel' | translate}}</div>
                        <div class="display-value d-flex flex-row">
                            <span>{{READONLYFORM.company.phone | display}}</span>
                        </div>
                    </ng-container>
                    <app-field-text 
                        *ngIf="formType == 'register'"
                        [model]="FORM.company.phone" 
                        (modelChange)="FORM.company.phone = $event"
                        [error]="validation.company.phone"
                        type="tel"
                        label="{{'register_contact_tel' | translate}}"
                        autocomplete="off"
                        placeholder="">
                    </app-field-text>
                </div>
            </div>

            <!-- company member of -->
            <div class="row row-form mw-700-px">
                <div class="col-md-12">
                    <ng-container *ngIf="formType != 'register'">
                        <div class="display-label">{{'register_company_member_of' | translate}}</div>
                        <div class="display-value d-flex flex-row">
                            <span>{{READONLYFORM.company.federations | display}}</span>
                        </div>
                    </ng-container>
                    <app-field-checkboxes 
                        *ngIf="formType == 'register'"
                        class="d-block"
                        label="{{'register_company_member_of' | translate}}"
                        [model]="FORM.company.federationIds" 
                        (modelChange)="handleCheckFederation($event)"
                        [returnChecked]="true"
                        [error]="validation.company.federationIds"
                        [options]="federations"
                        [optionWidth]=""
                        [optionColumnWidth]="">
                    </app-field-checkboxes>
                </div>
            </div>
        }

        <!-- admin set inactive -->
        <!-- <div class="row row-form mw-700-px" *ngIf="admin">
            <div class="col-12">
                <div class="display-label">{{'admincompany_status' | translate}}</div>
                <div class="display-value d-flex flex-row">
                    <span>{{('status_' + READONLYFORM.company.status | translate)}}</span>
                    <button style="font-size: 18px; margin-left: 12px; text-decoration: underline;" *ngIf="READONLYFORM.company.status === 1" (click)="setInactive.emit()" class="button-link">{{'admincompany_set_inactive' | translate}}</button>
                </div>
            </div>
        </div> -->

    </section>

    <section [class.active]="activeSection == 'user'" *ngIf="detail === 'profile' || user?.company?.type !== 'gov'">

        <h2 class="mb-s d-flex justify-content-between align-items-center" *ngIf="formType != 'firstlogin'">
            <span>{{'register_contact_title' | translate}}</span> 
            <div *ngIf="formType == 'profile' && !admin" class="flex-grow-1 d-flex justify-content-end align-items-center">
                <button *ngIf="activeSection != 'user'" class="button-link ml-m" (click)="editSection.emit('user')" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>{{'profile_edit' | translate}}</span></button>
                <button *ngIf="activeSection == 'user'" class="button-link cancel hide--xs" (click)="editSection.emit('')" [class.disabled]="saving"><span>{{'profile_cancel' | translate}}</span></button>
                <button *ngIf="activeSection == 'user'" class="button green ml-m hide--xs" (click)="saveSection.emit('user')" [class.disabled]="saving">{{'profile_save' | translate}}</button>
            </div>
        </h2>

        <!-- contact firstname lastname -->
        <div class="row row-form mw-700-px">
            <div class="col-md-5">
                <ng-container *ngIf="formType == 'profile' && activeSection != 'user'">
                    <div class="display-label">{{'register_contact_firstname' | translate}}</div>
                    <div class="display-value d-flex flex-row">
                        <span>{{READONLYFORM?.user?.firstname | display}}</span>
                    </div>
                </ng-container>
                <app-field-text 
                    *ngIf="formType != 'profile' || activeSection == 'user'"
                    [model]="FORM.user.firstname" 
                    (modelChange)="FORM.user.firstname = $event"
                    [error]="validation.user.firstname"
                    autocomplete="{{formType == 'register' ? 'given-name' : 'off'}}"
                    label="{{'register_contact_firstname' | translate}}"
                    placeholder="">
                </app-field-text>
            </div>
            <div class="col-md-7">
                <ng-container *ngIf="formType == 'profile' && activeSection != 'user'">
                    <div class="display-label">{{'register_contact_lastname' | translate}}</div>
                    <div class="display-value d-flex flex-row">
                        <span>{{READONLYFORM?.user?.lastname | display}}</span>
                    </div>
                </ng-container>
                <app-field-text 
                    *ngIf="formType != 'profile' || activeSection == 'user'"
                    [model]="FORM.user.lastname" 
                    (modelChange)="FORM.user.lastname = $event"
                    [error]="validation.user.lastname"
                    label="{{'register_contact_lastname' | translate}}"
                    autocomplete="{{formType == 'register' ? 'family-name' : 'off'}}"
                    placeholder="">
                </app-field-text>
            </div>
        </div>

        <!-- contact email -->
        <div class="row row-form mw-700-px">
            <div class="col-md-7">
                <ng-container *ngIf="formType != 'register'">
                    <div class="display-label">{{'register_contact_email' | translate}}</div>
                    <div class="display-value d-flex flex-row">
                        <span>{{READONLYFORM?.user?.email | display}}</span>
                    </div>
                </ng-container>
                <app-field-text 
                    *ngIf="formType == 'register'"
                    [model]="FORM.user.email" 
                    (modelChange)="FORM.user.email = $event"
                    [error]="validation.user.email"
                    type="email"
                    autocomplete="{{formType == 'register' ? 'email' : 'off'}}"
                    label="{{'register_contact_email' | translate}}"
                    placeholder="">
                </app-field-text>
            </div>
        </div>

        <!-- contact gsm -->
        <div class="row row-form mw-700-px">
            <div class="col-md-4">
                <ng-container *ngIf="formType == 'profile' && activeSection != 'user'">
                    <div class="display-label">{{'register_contact_gsm' | translate}}</div>
                    <div class="display-value d-flex flex-row">
                        <span>{{READONLYFORM?.user?.mobile | display}}</span>
                    </div>
                </ng-container>
                <app-field-text 
                    *ngIf="formType != 'profile' || activeSection == 'user'"
                    [model]="FORM.user.mobile" 
                    (modelChange)="FORM.user.mobile = $event"
                    [error]="validation.user.mobile"
                    optionalString="{{'misc_optional' | translate}}"
                    type="tel"
                    label="{{'register_contact_gsm' | translate}}"
                    autocomplete="{{formType == 'register' ? 'tel' : 'off'}}"
                    placeholder="">
                </app-field-text>
            </div>
        </div>

        <!-- language -->
        <div class="row row-form mw-700-px" *ngIf="formType != 'register'">
            <div class="col-md-12">
                <ng-container *ngIf="formType == 'profile' && activeSection != 'user'">
                    <div class="display-label">{{(!admin ? 'register_language' : 'registeradmin_language') | translate}}</div>
                    <div class="display-value d-flex flex-row">
                        <span>{{ResourceService.getLanguageByCode(READONLYFORM?.user?.language)?.title | display}}</span>
                    </div>
                </ng-container>
                <app-field-radios 
                    *ngIf="formType != 'profile' || activeSection == 'user'"
                    class="d-block"
                    [label]="'register_language' | translate"
                    [model]="FORM.user.language" 
                    (modelChange)="FORM.user.language = $event"
                    [error]="validation.user.language"
                    [options]="languageOptions"
                    [optionWidth]=""
                    [optionColumnWidth]="">
                </app-field-radios>
            </div>
        </div>

        <div class="show--xs--flex align-items-center mt-xxxs" *ngIf="formType == 'profile'">
            <button *ngIf="activeSection == 'user'" class="button green" style="padding: 10px 18px;" (click)="saveSection.emit('user')" [class.disabled]="saving">{{'profile_save' | translate}}</button>
            <button *ngIf="activeSection == 'user'" class="button-link cancel ml-m" (click)="editSection.emit('')" [class.disabled]="saving"><span>{{'profile_cancel' | translate}}</span></button>
        </div>

    </section>

    <section [class.active]="activeSection == 'password'" *ngIf="!admin">

        <h2 class="mb-s d-flex justify-content-between align-items-center">
            <span>{{'register_login_credentials_title' | translate}}</span> 
            <div *ngIf="formType == 'profile' && !admin" class="flex-grow-1 d-flex justify-content-end align-items-center">
                <button *ngIf="activeSection != 'password'" class="button-link ml-m" (click)="editSection.emit('password')" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>{{'profile_edit' | translate}}</span></button>
                <button *ngIf="activeSection == 'password'" class="button-link cancel hide--xs" (click)="editSection.emit('')" [class.disabled]="saving"><span>{{'profile_cancel' | translate}}</span></button>
                <button *ngIf="activeSection == 'password'" class="button green ml-m hide--xs" (click)="saveSection.emit('password')" [class.disabled]="saving">{{'profile_save' | translate}}</button>
            </div>
        </h2>

        <div class="row row-form mw-700-px" *ngIf="formType == 'firstlogin'">
            <div class="col-md-7">
                <ng-container>
                    <div class="display-label">{{'register_contact_email' | translate}}</div>
                    <div class="display-value d-flex flex-row">
                        <span>{{READONLYFORM?.user?.email | display}}</span>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- profile old password -->
        <div class="row row-form mw-700-px" *ngIf="formType == 'profile'">
            <div class="col-md-12">
                <ng-container *ngIf="activeSection != 'password'">
                    <div class="display-label">{{'profile_password' | translate}}</div>
                    <div class="display-value d-flex flex-row">
                        <span>{{'••••••••' | display}}</span>
                    </div>
                </ng-container>
                <app-field-password-check 
                    *ngIf="activeSection == 'password'"
                    [model]="FORM.password.oldPassword" 
                    (modelChange)="FORM.password.oldPassword = $event"
                    [hideCheck]="true"
                    (validChange)="oldPwdIsValid = $event"
                    [error]="validation.password.oldPassword"
                    label="{{'profile_old_password' | translate}}"
                    placeholder="">
                </app-field-password-check>
            </div>
        </div>
        <!-- profile new password -->
        <div class="row row-form mw-700-px" *ngIf="formType == 'profile' && activeSection == 'password'">
            <div class="col-md-12">
                <app-field-password-check 
                    [model]="FORM.password.newPassword" 
                    (modelChange)="FORM.password.newPassword = $event"
                    (validChange)="newPwdIsValid = $event"
                    [error]="validation.password.newPassword"
                    label="{{'profile_new_password' | translate}}"
                    placeholder="">
                </app-field-password-check>
            </div>
        </div>
        <div *ngIf="formType == 'profile'" class="mb-s"></div>

        <!-- password -->
        <div class="row row-form mw-700-px" *ngIf="formType != 'profile'">
            <div class="col-md-12">
                <app-field-password-check 
                    [model]="FORM.user.password" 
                    (modelChange)="FORM.user.password = $event"
                    (validChange)="pwdIsValid = $event"
                    [error]="validation.user.password"
                    label="{{'register_password' | translate}}"
                    placeholder="">
                </app-field-password-check>
            </div>
        </div>

        <div class="show--xs--flex align-items-center" *ngIf="formType == 'profile'" style="margin-top: -16px;">
            <button *ngIf="activeSection == 'password'" class="button green" style="padding: 10px 18px;" (click)="saveSection.emit('password')" [class.disabled]="saving">{{'profile_save' | translate}}</button>
            <button *ngIf="activeSection == 'password'" class="button-link cancel ml-m" (click)="editSection.emit('')" [class.disabled]="saving"><span>{{'profile_cancel' | translate}}</span></button>
        </div>

    </section>

    <!-- conditions check -->
    <div class="row row-form mw-700-px mt-m" *ngIf="formType != 'profile'">
        <div class="col-md-12">
            <label class="check d-inline-flex">
                <input type="checkbox" [(ngModel)]="FORM.user.acceptedTerms">
                <span class="fake-checkbox"></span>
                <span class="label">{{'register_conditions_check_1' | translate}} <a href="{{HelpersService.getUrl('terms')}}" target="_blank">{{'register_conditions_check_2' | translate}}</a> {{'register_conditions_check_3' | translate}} <a href="{{HelpersService.getUrl('privacy-policy')}}" target="_blank">{{'register_conditions_check_4' | translate}}</a></span>
            </label>
            <span *ngIf="validation.user.acceptedTerms" class="error-field-display--lone" style="margin-top: -2px;">{{validation.user.acceptedTerms}}</span>
        </div>
    </div>

</div>