<div class="container page">

    <!-- title -->
    <div class="d-flex justify-content-between align-items-end">
        <div><h1>{{'firstlogin_title' | translate}}</h1></div>
    </div>

    <div class="panel position-relative">

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">

            <div class="descr mb-xxs">
                {{'firstlogin_descr' | translate}}
            </div>
            
            <app-register-form
                [FORM]="formSTR"
                [READONLYFORM]="formSTR"
                [validation]="validation"
                formType="firstlogin"
            ></app-register-form>

            <div class="sep-h mt-xl" style="margin-bottom: 20px;"></div>

            <div class="d-flex align-items-center">
                <button [class.disabled]="saving" (click)="submit()" class="button green button--spacious justify-content-center"><span>{{'firstlogin_next' | translate}}</span></button>
            </div>

        </ng-container>

    </div>

</div>