import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { HelpersService } from './helpers.service';
import { Router } from '@angular/router';

import { tap } from 'rxjs/operators';
import { Config } from '../models/common';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user$ = new BehaviorSubject<object>(null);
    private config: Config;

    constructor(
        private ConfigService: ConfigService,
        private DefaultService: DefaultService,
        private HelpersService: HelpersService,
        private Router: Router
    ) {
        this.config = this.ConfigService.getConfig();
    }

    public login(FORM, token) {
        return this.DefaultService.userLogin(FORM, token).pipe(
            tap((next) => {
                // console.log(next);
            })
        );
        return new Observable((observer) => {}).pipe(tap((next) => {}));
    }

    public forgotPassword(FORM) {
        return this.DefaultService.userSendForgotPassword(FORM);
    }

    public register(FORM, token) {
        return this.DefaultService.registrationRegisterCompany(FORM, token);
    }

    public fetchUser(): any {
        return this.DefaultService.userMe().pipe(
            tap((next) => {
                this.setUser(next);
                if (!next.company) {
                    this.Router.navigate([this.HelpersService.getUrl('select-company')], {
                        queryParamsHandling: 'preserve'
                    });
                }
            })
        );
    }

    public selectCompany(id, backto?: string) {
        this.DefaultService.userSelectCompany(id).subscribe((next) => {
            backto ? (window.location.href = backto) : (window.location.href = this.HelpersService.getUrl('', 'home'));
        });
    }

    public updateUser(FORM) {
        return this.DefaultService.userUpdate(FORM);
    }

    public updateUserPassword(FORM) {
        return this.DefaultService.userUpdatePassword(FORM);
    }

    public logout() {
        this.DefaultService.userLogout().subscribe((next) => {
            if (this.ConfigService.getConfig().isIE) {
                this.Router.navigate([this.HelpersService.getUrl('', 'home')]);
                setTimeout(() => {
                    window.location.reload();
                }, 100);
            } else window.location.href = this.HelpersService.getUrl('', 'home');
        });
    }

    public softLogout() {
        this.DefaultService.userLogout().subscribe((next) => {
            this.setUser(null);
        });
    }

    public onUnauth() {
        // window.location.href = this.HelpersService.getUrl('', 'home');
    }

    public getUser(): Observable<object> {
        return this.user$.asObservable();
    }

    private setUser(user: object) {
        this.user$.next(user);
    }

    public getUserValue() {
        return this.user$.getValue() || null;
    }

    public userHasRole(code) {
        const user: any = this.getUserValue();
        return user && user.roles && user.roles.indexOf(code) != -1;
    }

    public isCurrentUserId(id) {
        return this.user$.getValue() && this.user$.getValue()['id'] == id;
    }

    public isFederation() {
        return (
            this.getUserValue() &&
            this.getUserValue()['roles'] &&
            this.getUserValue()['roles'].indexOf('federation') != -1
        );
    }
}
