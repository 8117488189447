import { Component, OnInit, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { HelpersService } from '../../../services/helpers.service';
import { TranslatorService } from '../../../services/translator.service';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter } from 'lodash';
import { startWith } from 'rxjs/operators';
import { DefaultService } from '../../../utils/api';
import { TranslatePipe } from '../../../utils/pipes';
import { ResourceService } from '../../../services/resource.service';

declare const grecaptcha: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    TranslatorService = inject(TranslatorService);
    TranslatePipe = inject(TranslatePipe);
    user: any = null;
    newsletterSTR: any = {
        email: '',
        language: this.TranslatorService.getCurrentLanguage(),
        species: null
    };
    validation: any = {};
    saving: boolean = false;
    showNewsletter: boolean = false;
    successState: boolean = false;
    newsletterSpeciesOptions: { title: string; value: string[] }[] = [
        { title: this.TranslatePipe.transform('newsletter_for_pork_beef'), value: ['pork', 'beef'] },
        { title: this.TranslatePipe.transform('newsletter_for_pork'), value: ['pork'] },
        { title: this.TranslatePipe.transform('newsletter_for_beef'), value: ['pork', 'beef'] }
    ];

    speciesPartCodes: ('pork' | 'beef')[] = [];

    isSlaughterhouseMonitor: boolean;

    weekInfo: any = {};

    constructor(
        private AuthenticationService: AuthenticationService,
        public HelpersService: HelpersService,
        public Router: Router,
        private Route: ActivatedRoute,
        private DefaultService: DefaultService,
        private ResourceService: ResourceService
    ) {}

    ngOnInit(): void {
        this.speciesPartCodes = this.ResourceService.getSpeciesPartsCodes();
        this.weekInfo = this.ResourceService.getWeekInfo();
        this.newsletterSTR.species = this.newsletterSpeciesOptions[0].value;
        this.user = this.AuthenticationService.getUserValue();
        this.HelpersService.getShowNewsletter().subscribe((next) => {
            this.showNewsletter = next;
        });
        let activatedRoute = this.Route.snapshot;
        while (activatedRoute.firstChild) {
            activatedRoute = activatedRoute.firstChild;
        }
        if (activatedRoute.data.isSlaughterhouseMonitor) {
            this.isSlaughterhouseMonitor = true;
        } else this.isSlaughterhouseMonitor = false;
        this.Router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                if (route.data.isSlaughterhouseMonitor) {
                    this.isSlaughterhouseMonitor = true;
                } else this.isSlaughterhouseMonitor = false;
            }
        });
    }

    getYear() {
        return new Date().getFullYear();
    }

    submitNewsletter() {
        let FORM = { ...this.newsletterSTR };
        if (this.speciesPartCodes?.length == 1) FORM.species = [this.speciesPartCodes[0]];
        this.saving = true;
        let that = this;
        grecaptcha.ready(function () {
            grecaptcha.execute('6LfXbsQaAAAAAIpbJZelj4SW37Md5HVBNHvP0kns', { action: 'submit' }).then(function (token) {
                that.DefaultService.subscriptionAddSubscription(FORM, token).subscribe({
                    next: (next) => {
                        that.validation = {};
                        that.saving = false;
                        that.successState = true;
                    },
                    error: (error) => {
                        that.saving = false;
                        if (error.error.error && error.error.error == 'captcha_verification') {
                            that.validation = {
                                captcha: 'generic_error_captcha_score'
                            };
                            return;
                        }
                        that.validation = error.error.details;
                    }
                });
            });
        });
    }
}
