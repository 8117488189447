<i class="im-icon im-icon-x-light" (click)="ActiveModal.dismiss()"></i>

<div class="mb-s" *ngIf="!toggleForgotPassword">
    <div class="title">{{'login_login_title' | translate}}</div>
    <div class="subtitle">{{'login_login_subtitle' | translate}}</div>
</div>
<div class="mb-s" *ngIf="toggleForgotPassword">
    <div class="title">{{'login_forgot_password_title' | translate}}</div>
    <!-- <div class="subtitle">{{'login_forgot_password_subtitle' | translate}}</div> -->
</div>

<!-- login form -->
<div class="form" *ngIf="!toggleForgotPassword">
    <!-- email -->
    <div class="row row-form">
        <div class="col-md-12">
            <app-field-text 
                [model]="loginSTR.email" 
                (modelChange)="loginSTR.email = $event"
                (enter)="submit()"
                typeClass="login"
                [error]="validation.email"
                type="email"
                label="{{'login_email' | translate}}"
                placeholder="{{'login_email_placeholder' | translate}}">
            </app-field-text>
        </div>
    </div>
    <!-- password -->
    <div class="row row-form">
        <div class="col-md-12">
            <app-field-text 
                [model]="loginSTR.password" 
                (modelChange)="loginSTR.password = $event"
                (enter)="submit()"
                typeClass="login"
                type="password"
                [error]="validation.password"
                label="{{'login_password' | translate}}"
                placeholder="{{'login_password_placeholder' | translate}}">
            </app-field-text>
        </div>
    </div>
    <span *ngIf="validation.captcha" class="error-field-display--lone" style="margin-top: 6px;">{{validation.captcha | translate}}</span>

    <button style="margin-top: 22px;" class="button green w-100 text-center button--spacious justify-content-center" (click)="submit()" [class.disabled]="saving"><span>{{'login_login_btn' | translate}}</span><i class="im-icon im-icon-arrow-right"></i></button>
    <button style="margin: auto; margin-top: 22px;" class="mt-s" (click)="toggleForgotPassword = true; validation = {}" class="toggle-show button-link">{{'login_forgot_pwd' | translate}}</button>
</div>

<!-- forgot password form -->
<div class="form" *ngIf="toggleForgotPassword">
    <!-- email -->
    <div class="row row-form">
        <div class="col-md-12">
            <app-field-text 
                [model]="forgotPasswordSTR.email" 
                (modelChange)="forgotPasswordSTR.email = $event"
                (enter)="forgotPassword()"
                typeClass="login"
                [error]="validation.email"
                type="email"
                label="{{'login_email' | translate}}"
                placeholder="{{'login_email_placeholder' | translate}}">
            </app-field-text>
        </div>
    </div>
    <button style="margin-top: 22px;" class="button green w-100 text-center button--spacious justify-content-center" (click)="forgotPassword()" [class.disabled]="saving"><span>{{'login_forgot_password_btn' | translate}}</span><i class="im-icon im-icon-arrow-right"></i></button>
    <button style="margin: auto; margin-top: 22px;" class="mt-s" (click)="toggleForgotPassword = false; validation = {}" class="toggle-show button-link">{{'login_cancel_toggle_forgot_password' | translate}}</button>
</div>

<div class="or-line d-flex align-items-center justify-content-center"><span class="line"></span><span class="or">{{'misc_or' | translate}}</span></div>

<!-- register -->
<div class="pt-xxxs">
    <div class="mb-xs">
        <div class="title">{{'login_register_title' | translate}}</div>
        <div class="subtitle">{{'login_register_subtitle' | translate}}</div>
    </div>
    <div class="descr">
        {{'login_register_descr' | translate}}
    </div>
    <button [routerLink]="[this.HelpersService.getUrl('register')]" (click)="ActiveModal.dismiss()" style="margin-top: 22px;" class="button green--light w-100 text-center button--spacious justify-content-center"><span>{{'login_register_btn' | translate}}</span><i class="im-icon im-icon-arrow-right"></i></button>
</div>