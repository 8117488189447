import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ResourceService } from './resource.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class RouteGuardService  {
    constructor(private router: Router, private AuthenticationService: AuthenticationService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // switch (next.component['name']) {
        //     case 'PickCompanyComponent':
        //         if (!this.AuthenticationService.getPickCompanyInfo()) {
        //             window.location.href = '/';
        //             return false;
        //         }
        // }
        return true;
    }
}
