import { Component, OnInit, inject } from '@angular/core';
import { TranslatePipe } from '../../utils/pipes';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
    TranslatePipe = inject(TranslatePipe);
    content: string = this.TranslatePipe.transform('terms_content');

    constructor(public Route: ActivatedRoute) {}

    ngOnInit(): void {}
}
