<div class="container page new-index">
    <div *ngIf="!ready" class="mt-l mb-xxl">
        <app-spinner></app-spinner>
    </div>

    <ng-container *ngIf="ready">
        <div class="d-flex justify-content-between">
            <h1>{{'newindex_input' | translate}} <span class="light">{{'newindex_week' | translate}} {{inputWeekInfo.weekNr}}</span></h1>
        </div>

        <p>{{'newindex_descr_1' | translate}} {{inputWeekInfo.weekNr}}. {{'newindex_descr_2' | translate}} {{inputWeekInfo.weekNr}} {{'newindex_descr_3' | translate}} {{weekInfo.input.end | formatTs: 'dddd DD MMMM HH:mm'}} {{'ts_hour' | translate}}.</p>

        <div class="panel">
            
            <ng-container *ngFor="let species of formSTR; index as $i">
                <!-- title -->
                <h2 class="mb-xs">{{'newindex_subtitle_' + species.activity | translate}} - {{'newindex_subtitle_sector_' + species.code | translate}}</h2>
                <!-- table -->
                <table class="index index-form hide--lgx" [class.index-form--has-price]="species.code === 'beef' || species.activity === 'provider'">
                    <tr>
                        <th class="head--part">{{'newindex_part' | translate}}</th>
                        <th class="head--cat" *ngIf="true || !IndexService.noCategories(species.parts)">{{'newindex_category' | translate}}</th>
                        <th class="head--traded">{{'newindex_traded_in_belgium' | translate}}</th>
                        <th class="head--priceprev" *ngIf="species.activity === 'provider' || species.code === 'beef'">{{'newindex_price_prev_week' | translate}}</th>
                        <th class="head--price" *ngIf="species.activity === 'provider' || species.code === 'beef'">{{'newindex_price_week' | translate}} {{inputWeekInfo.weekNr}}</th>
                        <th class="head--ticker" [title]="'newindex_difference_week' | translate">{{'newindex_difference_week' | translate}}</th>
                    </tr>
                    <tr *ngFor="let part of species.parts; index as $j" [class.tr--first]="IndexService.isFirstOfCategory(part.name, species.parts, $j)" [class.tr--last]="IndexService.isLastOfCategory(part.name, species.parts, $j)">
                        <!-- part -->
                        <td style="vertical-align: middle;" *ngIf="IndexService.isFirstOfCategory(part.name, species.parts, $j)" class="part" [attr.rowspan]="IndexService.isFirstOfCategory(part.name, species.parts, $j) ? IndexService.amountOfRows(part.name, species.parts) : 1">
                            <div class="d-flex align-items-center">
                                {{part.name}}
                            </div>
                        </td>
                        <!-- category -->
                        <td class="cat" *ngIf="true || !IndexService.noCategories(species.parts)">
                            {{part.cat}}
                        </td>
                        <!-- amount traded -->
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="input-wrap">
                                    <app-field-text 
                                        [model]="part.data.amount" 
                                        (modelChange)="part.data.amount = $event; changes = true; handleAmountChange(part.data)"
                                        (changed)="removeValidation($i, $j)"  
                                        [type]="'integer'"
                                        [instant]="true"
                                        (emitFocus)="part.data.amount === 0 ? part.data.amount = '' : ''"
                                        (emitBlur)="!part.data.amount ? part.data.amount = 0 : ''"
                                        [errorTooltip]="getValidation($i, $j).amount">
                                    </app-field-text>
                                </div>
                                <span *ngIf="part.unit" class="pieces">{{'newindex_unit_' + part.unit | translate}}</span>
                            </div>
                        </td>
                        <!-- previous price if provider -->
                        <td class="prevPrice" *ngIf="species.activity === 'provider' || species.code === 'beef'">
                            <div class="d-flex align-items-center">
                                <div>{{part.data.prevPrice | toFixed: 2}}</div>
                                <span *ngIf="part.data.prevPrice" class="pieces" style="transform: translateY(2px);">{{'unit_eur_' + part.unit | translate}}</span>
                            </div>
                        </td>
                        <!-- price -->
                        <td *ngIf="species.activity === 'provider' || species.code === 'beef'">
                            <div class="d-flex align-items-center">
                                <div class="input-wrap">
                                    <app-field-text 
                                        [model]="part.data.price" 
                                        [disabled]="!part.data.amount"
                                        (modelChange)="IndexService.handleChangePrice(part.data, $event); changes = true;"
                                        (changed)="removeValidation($i, $j)" 
                                        [type]="'currency'"
                                        [errorTooltip]="getValidation($i, $j).price">
                                    </app-field-text>
                                </div>
                                <span [class.downlight]="!part.data.amount" class="pieces">{{'unit_eur_' + part.unit | translate}}</span>
                                <div *ngIf="IndexService.priceTooSmall(part.data.price, part.minPrice)" class="app-tooltip-wrap" style="margin-right: 14px;">
                                    <i class="im-icon im-icon-warning-filled"></i>
                                    <div class="app-tooltip">
                                        {{'price_too_small_tooltip_warning' | translate}}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <!-- ticker index prev week -->
                        <td style="vertical-align: middle;">
                            <div class="d-flex align-items-center" [class.ticker-disabled]="!part.data.amount">
                                <!-- ticker -->
                                <span (click)="part.data.amount && IndexService.handleChangeFluctuation(part.data, 'down'); changes = true;" class="ticker-minus"><i class="im-icon im-icon-minus"></i></span>
                                <div class="ticker d-flex align-items-center" [ngClass]="IndexService.getDirectionClass(part.data.fluctuation)">
                                    <i class="im-icon im-icon-{{IndexService.getDirectionIcon(part.data.fluctuation)}}"></i> {{part.data.fluctuation | cent}} <span class="eur-kg">{{'unit_eur_' + part.unit | translate}}</span>
                                </div>
                                <span (click)="part.data.amount && IndexService.handleChangeFluctuation(part.data, 'up'); changes = true;" class="ticker-plus"><i class="im-icon im-icon-plus"></i></span>
                                <!-- tooltip -->
                                <div *ngIf="IndexService.fluctiationExceeds(part.data.fluctuation, part.maxFluctuation)" class="app-tooltip-wrap">
                                    <i class="im-icon im-icon-warning-filled"></i>
                                    <div class="app-tooltip">
                                        {{'newindex_tooltip_warning' | translate}}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>

                <!-- mobile -->
                <div class="index mobile-index show--lgx" [class.mobile-index--has-price]="species.code === 'beef' || species.activity === 'provider'">
                    <ng-container *ngFor="let part of species.parts; index as $j">
                        <div class="mobile-index-section d-flex flex-column" *ngIf="!part.spacer">
                            <!-- name, cat -->
                            <div class="d-flex align-items-end justify-content-between">
                                <div class="clear-width">
                                    <span class="mobile-index-part">{{part.name}}</span>
                                    <span class="mobile-index-cat">{{part.cat}}</span>
                                </div>
                                <!-- tooltip xs -->
                                <div *ngIf="IndexService.fluctiationExceeds(part.data.fluctuation, part.maxFluctuation)" class="app-tooltip-wrap clear-width show--xs">
                                    <i class="im-icon im-icon-warning-filled"></i>
                                    <div class="app-tooltip">
                                        {{'newindex_tooltip_warning' | translate}}
                                    </div>
                                </div>
                                <div *ngIf="IndexService.priceTooSmall(part.data.price, part.minPrice)" class="app-tooltip-wrap clear-width show--xs">
                                    <i class="im-icon im-icon-warning-filled"></i>
                                    <div class="app-tooltip">
                                        {{'price_too_small_tooltip_warning' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <!-- amount -->
                                <div class="d-flex flex-column">
                                    <span class="mobile-index-label">{{'newindexmobile_traded_in_belgium' | translate}}</span>
                                    <span class="mobile-index-value">
                                        <div class="d-flex align-items-center">
                                            <div class="input-wrap">
                                                <app-field-text 
                                                    [model]="part.data.amount" 
                                                    (modelChange)="part.data.amount = $event; changes = true; handleAmountChange(part.data)"
                                                    (changed)="removeValidation($i, $j)"  
                                                    [type]="'integer'"
                                                    [instant]="true"
                                                    (emitFocus)="part.data.amount === 0 ? part.data.amount = '' : ''"
                                                    (emitBlur)="!part.data.amount ? part.data.amount = 0 : ''"
                                                    [errorTooltip]="getValidation($i, $j).amount">
                                                </app-field-text>
                                            </div>
                                            <span *ngIf="part.unit" class="pieces">{{'newindex_short_unit_' + part.unit | translate}}</span>
                                        </div>
                                    </span>
                                </div>
                                <!-- prev price -->
                                <div class="d-flex flex-column" *ngIf="species.activity === 'provider' || species.code === 'beef'">
                                    <span class="mobile-index-label">{{'newindexmobile_prev_price' | translate}}</span>
                                    <span class="mobile-index-value">
                                        <div class="d-flex align-items-center">
                                            <div>{{part.data.prevPrice | toFixed: 2}}</div>
                                            <span *ngIf="part.data.prevPrice" class="pieces" style="transform: translateY(2px);">{{'unit_short_eur_' + part.unit | translate}}</span>
                                        </div>
                                    </span>
                                </div>
                                <!-- new price -->
                                <div class="d-flex flex-column price" *ngIf="species.activity === 'provider' || species.code === 'beef'">
                                    <span class="mobile-index-label">{{'newindexmobile_new_price' | translate}}</span>
                                    <span class="mobile-index-value">
                                        <div class="d-flex align-items-center">
                                            <div class="input-wrap">
                                                <app-field-text 
                                                    [model]="part.data.price" 
                                                    [disabled]="!part.data.amount"
                                                    (modelChange)="IndexService.handleChangePrice(part.data, $event); changes = true;"
                                                    (changed)="removeValidation($i, $j)" 
                                                    [type]="'currency'"
                                                    [errorTooltip]="getValidation($i, $j).price">
                                                </app-field-text>
                                            </div>
                                            <span [class.downlight]="!part.data.amount" class="pieces">{{'unit_short_eur_' + part.unit | translate}}</span>
                                            <div *ngIf="IndexService.priceTooSmall(part.data.price, part.minPrice)" class="app-tooltip-wrap hide--xs">
                                                <i class="im-icon im-icon-warning-filled"></i>
                                                <div class="app-tooltip">
                                                    {{'price_too_small_tooltip_warning' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <!-- ticker -->
                                <div class="d-flex flex-column">
                                    <span class="mobile-index-label">{{'newindexmobile_price_diff' | translate}}</span>
                                    <span class="mobile-index-value">
                                        <div class="d-flex align-items-center" [class.ticker-disabled]="!part.data.amount">
                                            <!-- ticker -->
                                            <span (click)="part.data.amount && IndexService.handleChangeFluctuation(part.data, 'down'); changes = true;" class="ticker-minus"><i class="im-icon im-icon-minus"></i></span>
                                            <div class="ticker d-flex align-items-center" [ngClass]="IndexService.getDirectionClass(part.data.fluctuation)">
                                                <i class="im-icon im-icon-{{IndexService.getDirectionIcon(part.data.fluctuation)}}"></i> {{part.data.fluctuation | cent}} <span class="eur-kg">{{'unit_short_eur_' + part.unit | translate}}</span>
                                            </div>
                                            <span (click)="part.data.amount && IndexService.handleChangeFluctuation(part.data, 'up'); changes = true;" class="ticker-plus"><i class="im-icon im-icon-plus"></i></span>
                                            <!-- tooltip -->
                                            <div *ngIf="IndexService.fluctiationExceeds(part.data.fluctuation, part.maxFluctuation)" class="app-tooltip-wrap hide--xs">
                                                <i class="im-icon im-icon-warning-filled"></i>
                                                <div class="app-tooltip">
                                                    {{'newindex_tooltip_warning' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                
            </ng-container>

            <div class="sep-h" style="margin-bottom: 20px;"></div>

            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center button-row">
                    <button [class.disabled]="saving" (click)="submit()" class="button green button--spacious justify-content-center"><span>{{'newindex_submit_btn' | translate}}</span></button>
                    <button [routerLink]="[this.HelpersService.getUrl('', 'home')]" class="button-link ml-s fontsize-l">{{'newindex_cancel_btn' | translate}}</button>
                </div>
                <div class="edited-by hide--sm" *ngIf="editName && editTS">
                    {{'newindex_last_edited_by' | translate}} {{editName}} {{'misc_date_on' | translate}} {{editTS | formatTs: 'DD MMM YYYY'}} {{'misc_date_at' | translate}} {{editTS | formatTs: 'HH:mm'}}.
                </div>
            </div>

        </div>

        <div class="edited-by edited-by--mobile show--sm" *ngIf="editName && editTS">
            {{'newindex_last_edited_by' | translate}} {{editName}} {{'misc_date_on' | translate}} {{editTS | formatTs: 'DD MMM YYYY'}} {{'misc_date_at' | translate}} {{editTS | formatTs: 'HH:mm'}}.
        </div>

    </ng-container>

</div>