import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class SpeciesService {
    constructor(private DefaultService: DefaultService, private ConfigService: ConfigService) {}

    public getAllSpeciesParts() {
        return this.DefaultService.resourcesGetAllSpeciesParts();
    }

    public getCompanySpeciesParts() {
        return this.DefaultService.companyGetSpeciesParts();
    }
    public getCompanyActivities() {
        return this.DefaultService.companyGetActivities();
    }

    public saveActivities(FORM) {
        return this.DefaultService.companySaveActvities(FORM);
    }

    public saveSpeciesParts(FORM) {
        return this.DefaultService.companySaveSpeciesParts(FORM);
    }

    public getSpeciesPartsStructured(speciesParts) {
        let structure = { beef: [], pork: [] };
        speciesParts.forEach((species) => {
            let currentIndex = 0;
            let currentName = '';
            for (let i = 0; i < species.parts.length; i++) {
                const item = species.parts[i];
                if (!currentName || currentName == item.name) {
                    if (!Array.isArray(structure[species.code][currentIndex])) {
                        structure[species.code][currentIndex] = [];
                    }
                    currentName = item.name;
                } else {
                    currentIndex++;
                    structure[species.code][currentIndex] = [];
                    currentName = item.name;
                }
                structure[species.code][currentIndex].push({
                    value: item.id,
                    title: `${item.name || ''} ${item.cat || ''}`,
                    tooltip: item.descr,
                    ...item
                });
            }
        });
        return structure;
    }
}
