<div class="field field-checkboxes d-flex flex-column" [class.hasOptionWidth]="!optionWidth || optionColumnWidth" [class.readOnly]="readOnly">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div class="{{!optionWidth && optionColumnWidth ? 'row' : 'd-flex'}} options flex-wrap">
        <div *ngFor="let option of options;" class="col-{{colSize || 'md'}}-{{(!optionWidth && optionColumnWidth) || ''}} option-wrap" [class.flex-grow-1]="fillWidth">
            <div [ngStyle]="{'min-width': !optionColumnWidth && optionWidth ? (optionWidth + 'px') : 'auto'}" (click)="(check(option.value))" [class.active]="isActive(option.value)" class="field-checkbox-option app-tooltip-wrap flex-grow-1 d-flex align-items-center">
                <span class="fake-checkbox"></span> <span>{{option.title}}</span>
                <div *ngIf="option.tooltip" class="app-tooltip app-tooltip-default-centered">{{option.tooltip}}</div>
            </div>
        </div>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>