<div class="container page">

    <!-- title -->
    <div class="d-flex justify-content-between align-items-end">
        <div><h1>{{'resetpassword_title' | translate}}</h1></div>
    </div>

    <div class="panel position-relative">

        <div *ngIf="!ready" class="mt-l mb-xxl">
            <app-spinner></app-spinner>
        </div>

        <ng-container *ngIf="ready">

            <div class="descr mb-l">
                {{'resetpassword_descr' | translate}}
            </div>
            
            <!-- password -->
            <div class="row row-form mw-700-px">
                <div class="col-md-12">
                    <app-field-password-check 
                        [model]="formSTR.password" 
                        (modelChange)="formSTR.password = $event"
                        (validChange)="pwdIsValid = $event"
                        [error]="validation.password"
                        label="{{'register_password' | translate}}"
                        placeholder="">
                    </app-field-password-check>
                </div>
            </div>

            <span *ngIf="validation.captcha" class="error-field-display--lone" style="margin-top: 6px;">{{validation.captcha | translate}}</span>

            <div class="sep-h mt-xl" style="margin-bottom: 20px;"></div>

            <div class="d-flex align-items-center">
                <button [class.disabled]="saving" (click)="submit()" class="button green button--spacious justify-content-center"><span>{{'resetpassword_next' | translate}}</span></button>
            </div>

        </ng-container>

    </div>

</div>