<div class="field field-radios d-flex flex-column" [class.hasOptionWidth]="!optionWidth || optionColumnWidth" [class.readOnly]="readOnly">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div class="{{!optionWidth && optionColumnWidth ? 'row' : 'd-flex'}} options">
        <div *ngFor="let option of options;" class="col-lg-{{(!optionWidth && optionColumnWidth) || ''}}" [class.flex-grow-1]="fillWidth">
            <div [ngStyle]="{'min-width': !optionColumnWidth && optionWidth ? (optionWidth + 'px') : 'auto'}" (click)="(canUncheck && option.value == model) ? emit(undefined) : emit(option.value)" [class.active]="option.value == model || (!option.value && !model)" class="field-radio-option app-tooltip-wrap flex-grow-1 d-flex align-items-center">
                <span class="fake-radio"></span> <span>{{option.title}}</span>
                <div *ngIf="option.tooltip" class="app-tooltip app-tooltip-default-centered">{{option.tooltip}}</div>
            </div>
        </div>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>