<div class="c"></div>
<img src="/assets/img/icons/round-check.svg" alt="" class="c-check">

<div class="wrap">

    <div class="mb-s">
        <div class="title">{{'newindexsuccess_title' | translate}}</div>
        <div class="subtitle">{{'newindexsuccess_subtitle' | translate}}</div>
    </div>

    <div class="descr">
        {{'newindexsuccess_descr' | translate}}
    </div>

    <button (click)="ActiveModal.dismiss()" style="margin-top: 26px;" class="button green--light w-100 text-center button--spacious justify-content-center"><span>{{'newindexsuccess_confirm' | translate}}</span></button>

</div>