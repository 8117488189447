import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class IndexService {
    constructor(private DefaultService: DefaultService) {}

    getDataInput(year?, weekNr?) {
        return this.DefaultService.dataInputGetDataInputForWeek(year, weekNr);
    }

    updateDataInput(FORM) {
        return this.DefaultService.dataInputUpdateDataInputForCurrentWeek(FORM);
    }

    public getIndexStructured(speciesARR) {
        let structure = { beef: { activity: '', parts: [] }, pork: { activity: '', parts: [] } };
        speciesARR.forEach((species) => {
            structure[species.code].activity = species.activity;
            let currentIndex = 0;
            let currentName = '';
            for (let i = 0; i < species.parts.length; i++) {
                const item = species.parts[i];
                if (!currentName || currentName == item.name) {
                    if (!Array.isArray(structure[species.code].parts[currentIndex])) {
                        structure[species.code].parts[currentIndex] = [];
                    }
                    currentName = item.name;
                } else {
                    currentIndex++;
                    structure[species.code].parts[currentIndex] = [];
                    currentName = item.name;
                }
                structure[species.code].parts[currentIndex].push({
                    value: item.id,
                    title: `${item.name || ''} ${item.cat || ''}`,
                    data: item.data || {},
                    tooltip: item.descr,
                    ...item
                });
            }
        });
        return structure;
    }

    fluctiationExceeds(fluctuation, max) {
        return Math.abs(fluctuation) > max;
    }

    priceTooSmall(price, min) {
        return price && price * 100 < min;
    }

    amountOfRows(categoryName, categoryARR) {
        return categoryARR.filter((item) => {
            return item.name == categoryName;
        }).length;
    }

    isFirstOfCategory(categoryName, categoryARR, index) {
        const i = categoryARR
            .map((item) => {
                return item.name;
            })
            .indexOf(categoryName);
        return index == i;
    }

    isLastOfCategory(categoryName, categoryARR, index) {
        const i = categoryARR
            .map((item) => {
                return item.name;
            })
            .lastIndexOf(categoryName);
        return index == i;
    }

    getDirectionClass(fluctuation) {
        if (!fluctuation) {
            return 'equal';
        }
        if (fluctuation > 0) {
            return 'up';
        }
        if (fluctuation < 0) {
            return 'down';
        }
        return null;
    }

    getDirectionIcon(fluctuation) {
        if (!fluctuation) {
            return 'equal';
        }
        if (fluctuation > 0) {
            return 'plus';
        }
        if (fluctuation < 0) {
            return 'minus';
        }
        return null;
    }

    handleChangePrice(data, price) {
        data.price = price;
        if (data.prevPrice) {
            if (data.price == data.prevPrice) {
                // equal
                data.fluctuation = 0;
            } else if (data.price > data.prevPrice) {
                //  up
                data.fluctuation = Math.round((data.price - data.prevPrice) * 100);
            } else if (data.price < data.prevPrice) {
                //  down
                data.fluctuation = Math.round((data.price - data.prevPrice) * 100);
            }
        }
    }

    handleChangeFluctuation(data, dir?) {
        if (dir == 'up') {
            data.fluctuation += 1;
        } else if (dir == 'down') {
            data.fluctuation -= 1;
        } else {
            // return;
        }
        if (data.prevPrice) {
            if (!data.fluctuation) {
                // equal
                data.price = data.prevPrice;
            } else if (data.fluctuation > 0) {
                //  up
                data.price = (data.prevPrice + data.fluctuation / 100).toFixed(2);
            } else if (data.fluctuation < 0) {
                //  down
                data.price = (data.prevPrice + data.fluctuation / 100).toFixed(2);
            }
        }
    }

    noCategories(parts) {
        return (
            parts.filter((item) => {
                return item.cat;
            }).length <= 0
        );
    }
}
