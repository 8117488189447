<div class="container page">

    <!-- title -->
    <div *ngIf="!successState" class="d-flex justify-content-between align-items-end">
        <div><h1>{{'subscriptioncancelled_title' | translate}}</h1></div>
    </div>

    <div class="panel position-relative" [class.hasState]="successState">

        <div *ngIf="successState" class="success-state">

            <div class="mb-s">
                <div class="title">{{'subscriptioncancelled_success_title' | translate}}</div>
            </div>

            <div class="descr">
                {{'subscriptioncancelled_descr' | translate}}
            </div>

            <button [routerLink]="[HelpersService.getUrl('', 'home')]" class="button-link mt-xxxs"><span style="font-size: 20px;">{{'subscriptioncancelled_next' | translate}}</span></button>

            <div class="c"></div>
            <img src="/assets/img/icons/round-check.svg" alt="" class="c-check">
        </div>

        <div class="prompt" *ngIf="!successState">
            <div class="descr mb-xxs">
                {{'subscriptioncancelled_descr_prompt' | translate}}
            </div>

            <div class="d-flex mt-s mb-xxs align-items-center">
                <button (click)="submit()" class="button green button--spacious justify-content-center" [class.disabled]="saving"><span>{{'subscriptioncancelled_confirm' | translate}}</span></button>
                <button class="button-link cancel ml-s" [routerLink]="[HelpersService.getUrl('', 'home')]" [class.disabled]="saving"><span>{{'subscriptioncancelled_cancel' | translate}}</span></button>
            </div>
        </div>

    </div>

</div>